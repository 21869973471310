import { menuDropdown } from "@/template/partials/customerMenu/menuDropDown";
import axios from "@/plugins/axios";

export default {
  methods: {
    async getPromotion(){
      
        try{
            const response = await axios.get('promotionMenu', {
                params: {
                    channel: this.user.VTWEG
                }
            });
            const promotion = response.data?.data?.promotion;

            if(promotion){
                const submenu = this.findSubmenu();
                if (!submenu) return;
                
                submenu.path = this.createPromotionPath(promotion);    
            }        
        }catch { 
          // Do nothing, just continue execution 
        }  
    },

    findSubmenu() {
        const menu = menuDropdown.find(item => item.text === 'Consulta');
        if (!menu) return null;

        return menu.menu.find(
            item => item.text === 'Promociones' && item.channels[0] == this.user.VTWEG
        );
    },

    createPromotionPath(promotion) {
        const baseUrl = process.env.VUE_APP_FILES_URL + '/storage/promotions/';
        return `${baseUrl}${promotion}`;
      },
  },

  watch: {
    $route() {
      this.getPromotion();
    }
  }
};

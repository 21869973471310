<template>
    <v-card outlined>
      <div class="px-5 pb-5" rounded="0">
        <div class="py-4">
          <icon-title text="Backorders activos" icon="mdi-file-document-alert-outline"  title-class="text-h6 text-md-h6 ml-2"/>
          <v-divider color="black"/>
        </div>
  
        <v-row justify="space-between">
          <v-col cols="auto" align-self="start">
            <v-btn class="red-button" @click="loadReminders()">Actualizar</v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <search-input 
              v-model="searchReminder"
              label="Buscar"
            />
          </v-col>
        </v-row>

        <reminders-table 
          :items="availableReminders"
          :search="searchReminder"
          :loading="loadingReminders"
          :hide-headers="hideTableHeaders()"
          no-show-select
          hide-delete-action
        />

        <material-status-helper class="my-5 my-md-5"/>

        <div class="d-flex justify-end" justify="end">
          <v-btn class="green-button w-xs-100" @click="goToReminders">Ir a backorders</v-btn>
        </div>
      </div>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SearchInput from '@/components/SearchInput.vue';
import RemindersTable from '@/components/tables/RemindersTable.vue';
import IconTitle from '@/components/IconTitle.vue';
import MaterialStatusHelper from '@/components/MaterialStatusHelper.vue';

export default {
  name: 'RemindersStep',

  components: { SearchInput, RemindersTable, IconTitle, MaterialStatusHelper },

  data: () => ({
    searchReminder: '',
  }),

  methods: {
    ...mapActions('reminders', [
      'loadReminders',
      'changeRemindersModalState',
    ]),

    goToReminders () {
      this.$emit('close-modal');
      this.$router.push({ name: 'backordersHistory'});
    },

    hideTableHeaders(){

     if (!this.$hasAnyRole(['Customer', 'CustomerPR'])) {
      return ['manager','seller','regManager','actions','discountPrice'];
     }else{
      return ['seller','manager','customer','regManager','actions'];
     }
    }
  },

  computed: {
    ...mapGetters('reminders', [
      'availableReminders',
      'loadingReminders',
    ]),
  },
};
</script>
import store from "@/store";
//import {hasAnyRole} from "./index"
import DailyHome from '../views/diary/director/Home.vue'; 
import DirectorChannelSelection from "@/views/auth/DirectorChannelSelection.vue";

export const hasAnyRole = (storeroles, roles) => {
  return storeroles.some(role => roles.includes(role.name));
};

export default [
  
    { 
        path: "/daily",
        name: "DailyDirector",
        component: DailyHome,
        meta: {role: 'Director'},
        props: true,
        beforeEnter: (to, from, next) => {
            if(!store.getters["auth/authenticatedDirector"]){
              return next({
                name: "DirectorChannelSelection",
              });
            } 
            if (!hasAnyRole(store.getters["auth/roles"], ["Director", "IELDirector", 
              "IELCommercialPR", "IELCommercialDT","Subdirector"])) {
              return next({ name: "SignIn" });
            }
            next();
          },
      },
      { 
        path: "/daily-sub-director",
        name: "DailySubDirector",
        component: DailyHome,
        meta: {role: 'Subdirector'},
        props: true,
        beforeEnter: (to, from, next) => {
            if(!store.getters["auth/authenticatedDirector"]){
              return next({
                name: "DirectorChannelSelection",
              });
            } 
            if (!hasAnyRole(store.getters["auth/roles"], ["Director", "IELDirector", 
              "IELCommercialPR", "IELCommercialDT","Subdirector"])) {
              return next({ name: "SignIn" });
            }
            next();
          },
      },
      {
        path: "/rg-channel-selection",
        name: "RGChannelSelection",
        component: DirectorChannelSelection,
        meta: {role: 'Regional'},
        beforeEnter: (to, from, next) => {
          if (!store.getters["auth/authenticated"]) {
            return next({
              name: "SignIn",
            });
          } 
          if (!hasAnyRole(store.getters["auth/roles"], ["RegionalManager", "IELRegManager"])) {
            return next({ name: "SignIn" });
          }
          next();
        }
      },
      { 
        path: "/daily-regional-manager",
        name: "DailyRegionalManager",
        component: DailyHome,
        meta: {role: 'Regional'},
        props: true,
        beforeEnter: (to, from, next) => {
          
            if(!store.getters["auth/authenticatedDirector"]){
             
              return next({
                name: "DirectorChannelSelection",
              });
            } 
            if (!hasAnyRole(store.getters["auth/roles"], ["Director", "IELDirector", 
              "IELCommercialPR", "IELCommercialDT","Subdirector","RegionalManager", "IELRegManager"])) {
              
              return next({ name: "SignIn" });
            } 
            next();
          },
      },
]


import { render, staticRenderFns } from "./RowTableMovements.vue?vue&type=template&id=28383103&scoped=true&"
import script from "./RowTableMovements.vue?vue&type=script&lang=js&"
export * from "./RowTableMovements.vue?vue&type=script&lang=js&"
import style0 from "./RowTableMovements.vue?vue&type=style&index=0&id=28383103&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28383103",
  null
  
)

export default component.exports
<template>
  <v-dialog
    v-model="isDialogOpen"
    max-width="1050px"
  >
    <v-card
      :class="{ 'rounded-r-xl': !isMobile }"
      style="overflow: clip"
    >
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col
            cols="12"
            md="3"
            style="background-color: #db0218"
            class="elevation-1"
          >
            <v-row
              no-gutters
              align="center"
              class="fill-height"
            >
              <p class="ma-0 text-white py-2 h6">Detalle del Producto</p>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="9"
            class="pa-10"
          >
            <div class="d-flex align-center flex-column flex-grow-1">
              <img
                src="/img/propuesta-1-x2.svg"
                alt="Logotipo IUSA En Linea"
                style="height: 45px; user-select: none"
                draggable="false"
              />
              <div
                class="mt-5 border pa-3 rounded-lg"
                style="width: 100%; border-width: 4px"
              >
                <p class="font-weight-black h5">Detalle del Producto</p>
                <div class="d-flex flex-column mb-5">
                  <div
                    class="d-flex justify-center"
                    :class="{ 'align-center': !productImages }"
                    style="min-height: 150px"
                  >
                    <v-progress-circular
                      v-if="!productImages"
                      indeterminate
                      color="primary"
                    />
                    <v-carousel
                      v-else
                      cycle
                      interval="3000"
                      continuous
                      :show-arrows="false"
                      hide-delimiter-background
                      height="100"
                    >
                      <v-carousel-item
                        v-for="image in productImages"
                        :key="image.src"
                      >
                        <v-img
                          :src="image.src"
                          contain
                          height="100"
                        />
                      </v-carousel-item>
                    </v-carousel>
                  </div>
                  <p class="mb-0">
                    {{ productName || '' }}
                  </p>
                </div>
                <slot></slot>
                <div class="d-flex flex-column align-center mt-5">
                  <p class="font-weight-black">
                    Diagramas, manuales e información técnica
                  </p>
                  <div class="d-flex align-end flex-wrap justify-center">
                    <div
                      v-for="file in files"
                      :key="file.id"
                      class="px-2"
                      style="max-width: 150px"
                    >
                      <p class="ma-0 pb-1">{{ file.label }}</p>
                      <v-btn
                        v-bind:href="file.src"
                        :loading="isLoadingFiles"
                        :disabled="!file.src"
                      >
                        <v-icon color="red red-darken-4">
                          mdi-folder-download
                        </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <v-btn
                class="red-button mt-5 rounded-lg align-self-end"
                @click="onCloseDialog"
              >
                CERRAR
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import axios from '@/plugins/axios';
  export default {
    name: 'ProductTechnicalSheets',
    props: {
      product: {
        type: Object,
      },
      value: {
        type: Boolean,
        required: true,
      },
    },
    data: () => {
      return {
        productImages: null,
        isLoadingFiles: true,
        files: [
          { id: 'sheet', type: 'Fichas', label: 'Ficha Técnica', src: null },
          {
            id: 'manual',
            type: 'Manuales',
            label: 'Manual o Diagrama de Instalación',
            src: null,
          },
        ],
      };
    },
    methods: {
      resetData() {
        this.productImages = null;
        this.files = this.files.map((file) => ({ ...file, src: null }));
        this.isLoadingFiles = false;
      },
      onCloseDialog() {
        this.isDialogOpen = false;
        this.resetData();
      },
      async getProductImages(sku) {
        try {
          const { data } = await axios.get(
            'https://bancodeimagenes.iusa.com.mx/api/listDropboxNew',
            {
              params: { code: sku, view: 0, quant: 0 },
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer token',
                'api-key':
                  '$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
              },
            }
          );

          // Ignores the last element in the array that corresponds to the response metadata
          // And returns a new transformed array with the shape [{id, name, src}]
          const images = data.slice(0, data.length - 1).map((image) => {
            return {
              id: image.id,
              name: image.name,
              src: image.path,
            };
          });

          this.productImages = images;
        } catch (error) {
          this.productImages = null;
          this.$toast.error(
            'Ocurrió un error al obtener la imagen del producto'
          );
        }
      },
      async getProductFiles(sku) {
        this.isLoadingFiles = true;

        try {
          const { data } = await axios.get(
            'https://bancodeimagenes.iusa.com.mx/api/allFileDropboxPdf',
            {
              params: { code: sku, type: 6 },
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer token',
                'api-key':
                  '$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC',
              },
            }
          );

          /* 
            Ignores the last element in the array, which corresponds to the response metadata,
            and returns a new transformed array with the shape [{ id, name, src, type }] 
          */

          const files = data.slice(0, data.length - 1).map((file) => {
            return {
              id: file.id,
              name: file.name,
              src: file.path,
              type: file.type_file,
            };
          });

          /* 
            Maps through the transformed array of files to update the `src` property of the files array in `data()`
            Only the files that match the specified "type" are updated
            The accepted file types are "Fichas" and "Manuales" 
          */

          files.forEach(({ type, src }) => {
            const index = this.files.findIndex((file) => file.type === type);
            if (index === -1) return;

            this.files.splice(index, 1, { ...this.files[index], src });
          });

          this.isLoadingFiles = false;
        } catch (error) {
          this.isLoadingFiles = false;
        }
      },
    },
    computed: {
      isDialogOpen: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      isMobile() {
        return (
          this.$vuetify.breakpoint.name === 'xs' ||
          this.$vuetify.breakpoint.name === 'sm'
        );
      },
      productSKU() {
        return this.product?.codigo_material;
      },
      productName() {
        return `${this.productSKU || ''} ${
          this.product?.nombre_material || ''
        }`;
      },
    },
    watch: {
      isDialogOpen(isOpen) {
        if (!isOpen || !this.product) return;

        // Fetch product information on every dialog open
        this.getProductImages(this.productSKU);
        this.getProductFiles(this.product.codigo_material);
      },
    },
  };
</script>

<style scoped>
  :deep(.v-window) {
    overflow: visible !important;
  }

  :deep(.v-carousel) {
    overflow: visible !important;
  }

  :deep(.v-carousel__controls) {
    position: absolute !important;
    bottom: -50px !important;
  }

  :deep(.v-carousel__controls__item) {
    background-color: white !important;
    border: 2px solid black !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
  }

  :deep(.v-carousel__controls__item .v-icon) {
    display: none !important;
  }

  :deep(.v-carousel__controls__item.v-item--active) {
    background-color: black !important;
    border: 2px solid black !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 50%;
  }
</style>

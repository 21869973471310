import { eventBus } from "@/main";
import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    token: null,
    user: {},
    username:{},
    alert: false,
    message: null,
    must_change_password: false,
    notifications: [],
    contacts: [],
    permissions: [],
    roles: [],
    customerData: [],
    userEmail:"",
    userPhone:"",
  },
  getters: {
    userPhone(state){
      return state.userPhone;
    },
    userEmail(state){
      return state.userEmail;
    },
    customerData(state){
      return state.customerData;
    },
    authenticated(state) {
      return state.token && state.user;
    },
    authenticatedDirector(state) {
      return state.token && state.user && state.user.VTWEG != null;
    },
    user(state) {
      return state.user;
    },
    username(state) {
      return state.username;
    },
    token(state) {
      return state.token;
    },
    alert(state) {
      return state.alert;
    },
    message(state) {
      return state.message;
    },
    notifications(state) {
      return state.notifications;
    },
    role(state) {
      return state.user.role;
    },
    contacts(state) {
      return state.contacts;
    },
    roles: (state) => state.roles,
    permissions: (state) => state.permissions,
    channel: (state) => state.user?.VTWEG || '',
    userCode: (state) => state.user?.employee_number || state.user?.custumer_code || '',
    mustchangepassword: (state) => state.must_change_password,
  },
  mutations: {
    SET_USER_EMAIL(state,data){
      return state.userEmail = data;
    },
    SET_USER_PHONE(state,data){
      return state.userPhone = data;
    },
    SET_CUSTOMER_DATA(state,data){
      return state.customerData = data;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, data) {
        state.user = data;
      },
    SET_USER_NAME(state, data) {
      state.username = data;
    },
    SET_VISIBLE_ALERT(state, data) {
      state.alert = true;
      state.message = data;
      setTimeout(() => { state.alert = false; state.message = null; }, 5000);
    },
    SET_NOTIFICATIONS(state, data) {
      state.notifications = data;
    },
    ADD_NEW_NOTIFICATION(state, data) {
      let newNotification = { id: data.id, type: data.type, data: data };

      state.notifications.unshift(newNotification);
      eventBus.$emit('clearData');
    },
    MARK_NOTIFICATION_READED(state, id) {
      let index = state.notifications.findIndex(item => item.id == id);
      state.notifications.splice(index, 1);
    },
    SET_CONTACTS(state, data) {
      state.contacts = data;
    },
    SET_ROLES(state, data) {
      state.roles = data;
    },
    SET_PERMISSIONS(state, data) {
      state.permissions = data;
    },
    SET_CHANGE_PASSWORD(state, data) {
      state.must_change_password = data;
    },
  },
  actions: {
     loadCustomerData({ state, commit }, payload) {
      return axios.post('/getCustomerInformation', payload, {
        headers: {
          Authorization: 'Bearer ' + state.token,
        }
      })
        .then((response) => {
          commit('SET_CUSTOMER_DATA', response.data);
          return response.data
        })
        .catch((error) => {
          console.error('Error en la petición al cargar los datos de cliente /getCustomerInformation:', error);
          console.log(payload)
        });
    },

    async signIn({ commit, dispatch }, credentials) {
      // let response = await axios.post("signin", { ...credentials, platform: 'IEL' });
      let response = await axios.post(`${process.env.VUE_APP_BASE_SERVER}/auth/login`, credentials);
      commit('SET_TOKEN', response.data.token);

      localStorage.setItem("username", response.data.username);
      return dispatch("attempt", response.data.token);
    },
    async attempt({ commit, state }, token) {
      if(token){
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }
      try {
        let response = await axios.get(`${process.env.VUE_APP_API_SERVER}/info`, {
          headers: {
            Authorization: 'Bearer ' + token,
          }
        });

        /**
         * Obtenención del canal almacenado de los roles determinados y asignarlo a USER
         */
        const rolesToCheck = ['Director', 'IELDirector', 'IELExpressSales', 'IELBriefcase',];
        if (rolesToCheck.includes(response.data.roles[0].name)) {
          let channel = localStorage.getItem("channel");
          response.data.VTWEG = channel || null;
        }

        commit("SET_USER", response.data);
        commit("SET_USER_NAME", response.data.username);
        localStorage.setItem("id", response.data.id);
        commit("SET_ROLES", response.data.roles);
        commit("SET_PERMISSIONS", response.data.permissions);
        commit("SET_CHANGE_PASSWORD", response.data.must_change_password);
      } catch (error) {
        commit('SET_TOKEN', null)
        commit('SET_USER', null)
      }
    },
    async signOut({ commit }) {
      return axios.post(`${process.env.VUE_APP_BASE_SERVER}/auth/logout`).then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      });
    },
    loadNotifications({ commit }) {
      axios.get("notifications").then(response => {
        commit("SET_NOTIFICATIONS", response.data.data);
      });
    },
    async markAsRead({commit}, id) {
      console.log('Notification', id);
      return axios.put(`notifications/${id}`).then(() => {
        commit("MARK_NOTIFICATION_READED", id);
      });
    },
    async loadContact({ state, commit }) {
      return axios.get(`customers/${state.user.customer_data_id}/contacts`).then(response => {
        commit("SET_CONTACTS", response.data);
      });
    },
    async sigInVel({ state }, password) {
      return axios.post(`/signin`, { employee_number: state.user.employee_number, password: password, platform: 'VEL' }).then(response => {
      // return axios.post(`/${state.user.id}`).then(response => {
        return response.data;
      }).catch(error => {
        console.log(error);
        throw error;
      });
    },
    async actualizarUsuario({ commit, state }, canal) {
      try {
        const data = {}
        data.VTWEG = canal
        commit('SET_USER', { ...state.user, ...data });
      } catch (error) {
        console.error('Error al actualizar el usuario', error);
        throw error; // Puedes re-lanzar el error si es necesario
      }
    },
    hasAnyChannel({ getters }, channels){
      if(channels){
        return channels.includes(getters.channel);
      }

      return false;
    },
    hasAnyRole({ getters }, roles){
      if(roles){
        return getters.roles.some(role => roles.includes(role.name));
      }

      return false;
    }
  },
};

import axios from "@/plugins/axios";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    //type: "CL", // Constante para IEL
    destinations: [],
    orderId: null,
    order: null,
    increase: 0,
    cart: [],
    iva:0,
    customerIva:0,
    currentProductIndex: null,
  },
  getters: {
    orderId: (state) => state.orderId,
    order: (state) => state.order,
    destinations: (state) => state.destinations,
    increase: (state) => state.increase,
    cart: (state) => state.cart,
    subtotal: (state) =>
    state.cart
      .map((item) => item.importe_producto)
      .reduce((a, b) => a + b, 0),
    //iva: (state) => state.iva,
    iva:  (state) =>
    state.cart
      .reduce((a, item) => {
        const lowerIva = getLowerIva(state.customerIva, item.MWST);
        const iva = parseFloat(item.importe_producto) * lowerIva;
        return a + iva
      },0),
    total: (state, getters) => getters.subtotal + getters.iva,
    subtotalCred: (state) =>
    state.cart
    .reduce((a, item) => {
      const lowerIva = getLowerIva(state.customerIva, item.MWST);
      const iva = (item.v_credit == 1  ? parseFloat(item.importe_producto) : 0) ? lowerIva * parseFloat(item.importe_producto) : 0
      const sub = (item.v_credit == 1  ? parseFloat(item.importe_producto) : 0)
      const total = iva + sub
      return a + total
    },0),
    lowPrice: (state) =>
    Math.min.apply(null,state.cart.map((item) => { 
      const lowerIva = getLowerIva(state.customerIva, item.MWST);
      const iva = parseFloat(item.importe_producto) * lowerIva;
      return iva + item.importe_producto
    })),
    counItem: (state) => state.cart.length,
    currentProduct: (state) => {
      return state.cart[state.currentProductIndex] || undefined;
    }
  },
  mutations: {
    UPDATE_STATE(state, payload) {
      state[payload.key] = payload.value;
    },
    SET_DESTINATIONS(state, data) {
      state.destinations = data;
    },
    SET_CUSTOMER_IVA(state, taxkd) {
      state.customerIva = parseFloat(taxkd) || 0.16;  
    },
    SET_ORDER_ID(state, orderId) {
      state.orderId = orderId;
    },
    SET_CART(state, cart) {
      state.cart = cart;
    },
    SET_IVA(state, iva) {
      state.iva = iva;
    },
    SET_IMAGE_ITEM(state, payload) {
      state.cart[payload.index].image_url = payload.path;
    },
    SET_IMAGE_ITEM_ADD(state, payload) {
      state.cart[payload.index].image_url = payload.path;
    },
    ADD_ITEM(state, item) {
      state.cart.unshift(item);
    },
    UPDATE_ITEM(state, payload) {
     let index = state.cart.findIndex(
        (item) => item.codigo_material == payload.codigo_material
      );
      Vue.set(state.cart, index,  payload);
    },
    DELETE_FROM_CART(state, code) {
      let index = state.cart.findIndex((item) => item.codigo_material == code);
      if (index >= 0) {
        state.cart.splice(index, 1);
        return true;
      }
      return false;
    },
    CLEAN_CART(state, cart){
      state.cart = cart;
    },
    SET_ORDER_NAME(state, order){
      state.order = order;
    },
    SET_PRODUCT_IMAGES(state, {productIndex, images}) {
      state.cart.splice(productIndex, 1, {
        ...state.cart[productIndex],
        images: images,
      });
    },
    SET_CURRENT_PRODUCT_INDEX(state, index) {
      state.currentProductIndex = index;
    }
  },
  actions: {
    async loadCustomerDestinations({ commit }, payload) {
      axios
        .post("destinyCustomer", {
          customer: payload.custumer_code,
          VKORG: payload.VKORG,
          VTWEG: payload.VTWEG,
          VKBUR: payload.VKBUR,
        })
        .then((response) => {
          commit("SET_DESTINATIONS", response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async loadOrder({ commit, rootState, dispatch }, payload) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "get-order-id-pr":"getOrderID" , {
          customer: payload.custumer_code,
          user_id: payload.user_id,
          type: payload.type,
          doctype: payload.doctype,
          idq: payload.idq,
          channel: rootState.auth.user.VTWEG,
        })
        .then((response) => {
          
          commit("SET_ORDER_ID", response.data);
          dispatch("getOrderInfo");
          this.orderId = response.data.order_id;
          return this.orderId;
        });
    },
    async getOrderInfo({ commit, rootState, state }) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "get-order-info-pr":"getOrderInfo" , {
          id_order: state.orderId ,
        })
        .then((response) => {
          commit("SET_ORDER_NAME", response.data.name)
          this.order = response.data.name;
          return this.order;
        });
    },
    async loadCartItems({ state, commit, dispatch, rootState }, payload) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "get-cart-items-pr":"getCartItems", {
          id_order: state.orderId,
          customer: payload.custumer_code,
          VKORG: payload.VKORG,
          VTWEG: payload.VTWEG,
          VKBUR: payload.VKBUR,
          doctype: payload.doctype,
          order_type: payload.doctype,
        })
        .then((response) => {
          commit("SET_CART", response.data);
          dispatch("loadCartImages");
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async getMaterialInfo(_, payload) {
      return axios
        .post(payload.VTWEG == "PR" ? "get-material-info-pr":`getMaterialInfo`, {
          code: payload.sku,
          units: payload.quantity,
          customer: payload.custumer_code,
          VKORG: payload.VKORG,
          VTWEG: payload.VTWEG,
          VKBUR: payload.VKBUR,
          doctype: payload.order_type,
        })
        .then((response) =>  response.data
        )
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async saveNewMaterialOrder({ commit, dispatch, rootState  }, payload) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? 'save-new-material-order-pr':`saveNewMaterialOrder`, {
          order_id: payload.order_id,
          item_cart: payload.item,
        })
        .then((response) => {
          commit("ADD_ITEM", payload.item);
          //dispatch("getImageUrl");
          dispatch("loadCartImages");
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async deleteMaterialOrder({ commit, rootState  }, { order_id, item }) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "delete-material-orde-pr":"deleteMaterialOrder", {
          order_id: order_id,
          item_cart: item,
        })
        .then((response) => {
          console.log("Action finished:", response.data);
          console.log("item", item);
          commit("DELETE_FROM_CART", item.codigo_material);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    loadCartImages({ state, commit }) {
      for (let i = 0; i < state.cart.length; i++) {
        axios
          .get(
            `https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code=${state.cart[i].codigo_material}&quant=1&view=0`,
            {
              headers: {
                "Content-Type": "application/json",
                "api-key":
                  "$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC",
                Authorization: "Bearer token",
              },
            }
          )
          .then((response) => {
            commit("SET_IMAGE_ITEM", { index: i, path: response.data[0].path });
          });
      }
    },
    async getImageUrl({ state, commit }) {
      return axios
        .get(
          "https://bancodeimagenes.iusa.com.mx/api/listDropboxNew?code=" +
          state.cart.codigo_material +
            "&quant=1&view=0",
          {
            headers: {
              "Content-Type": "application/json",
              "api-key":
                "$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC",
              Authorization: "Bearer token",
            },
          }
        )
        .then((response) => {
          if(response.data[0].path) {
            let index = state.cart.findIndex(element => element.codigo_material == state.cart.codigo_material);
  
            state.cart[index].image_url = response.data[0].path
            commit("SET_IMAGE_ITEM_ADD", state.cart[index].image_url);
          }
          //this.validacionCredicticia();
        })
        .catch((error) => {
          throw error;
        });
    },
    async updateMaterialQuantity({ commit, dispatch, rootState }, payload) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "get-material-info-pr":"getMaterialInfo", {
          code: payload.codigo_material,
          units: payload.quantity,
          //customer: rootState.auth.user.custumer_code,
          customer: payload.custumer_code,
          VKORG: rootState.auth.user.VKORG,
          VTWEG: rootState.auth.user.VTWEG,
          VKBUR: rootState.auth.user.VKBUR,
          doctype: payload.order_type,
        })
        .then((response) => {
          const data = response.data;
          commit("UPDATE_ITEM", data);
          //commit("SET_CART",payload.cart_i);

          return dispatch("updateMaterialOrder", data);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },

    async updateMaterialOrder({state, rootState, dispatch }, item) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "update-material-order-pr":"updateMaterialOrder", {
          order_id: state.orderId,
          item_cart: item,
          customer: rootState.auth.user.custumer_code,
          VKORG: rootState.auth.user.VKORG,
          VTWEG: rootState.auth.user.VTWEG,
          VKBUR: rootState.auth.user.VKBUR,
        })
        .then((response) => {
          response.data
          //const data = response.data;
           //commit("UPDATE_ITEM", item);
         //commit("SET_CART", item);
        //  dispatch("loadCartItems");
          dispatch("loadCartImages");
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },

    async generateOrder({ state, rootState }, payload) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "generate-order-customer-pr" : "generateOrderCustomer", {
          order_id: state.orderId,
          orden_compra: payload.orden_compra,
          destino_compra: payload.destiny,
          carrito: payload.carrito,
          customer: payload.custumer_code,
          VKORG: rootState.auth.user.VKORG,
          VTWEG: rootState.auth.user.VTWEG,
          VKBUR: rootState.auth.user.VKBUR,
          numero_vendedor: payload.numero_vendedor,
          order_type: payload.orderType,
          bandera_programado: payload.banderaProgramado,
          purchase_validation: payload.purchase_validation,
          documents: rootState.auth.user.VTWEG == "PR" ? payload.documents:"",
          v_cfdi: rootState.auth.user.VTWEG == "PR" ? payload.v_cfdi:"",
          select_metodo: rootState.auth.user.VTWEG == "PR" ? payload.select_metodo:"",
          select_v_pay:  rootState.auth.user.VTWEG == "PR" ? payload.select_v_pay:"",
          select_use_cfdi:  rootState.auth.user.VTWEG == "PR" ? payload.select_use_cfdi:"",
          schedule_orders: payload.schedule_orders,
          reminder_date: payload.schedule_date,
          quoter: payload.quoter,
        })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async cleanMaterialOrder( { commit, rootState }, payload) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "cleanMaterialOrderPR":"cleanMaterialOrder", {
          order_id: payload.order_id,
        })
        .then((response) => {

          console.log(response);
          let cart = [];
          return commit("CLEAN_CART", cart);
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },

    async loadOrderBack({ commit, rootState }, payload) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "get-order-id-reminder-pr":"getOrderIDReminder" , {
          customer: payload.customer,
          order_id: payload.order_id,
          dd: 0,
        })
        .then((response) => {

          commit("SET_ORDER_ID", response.data.id);
          this.orderId = response.data.id;
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
    async generateOrderBack({ state, rootState }, payload) {
      return axios
        .post(rootState.auth.user.VTWEG == "PR" ? "generate-order-reminder-customer-pr" : "generateOrderReminderCustomer", {
          order_id: state.orderId,
          orden_compra: payload.orden_compra,
          destino_compra: payload.destiny,
          carrito: payload.carrito,
          //customer: rootState.auth.user.custumer_code,
          customer: payload.custumer_code,
          VKORG: rootState.auth.user.VKORG,
          VTWEG: rootState.auth.user.VTWEG,
          VKBUR: rootState.auth.user.VKBUR,
          numero_vendedor: payload.numero_vendedor,
          order_type: payload.orderType,
          bandera_programado: payload.banderaProgramado,
          purchase_validation: payload.purchase_validation,
          documents: rootState.auth.user.VTWEG == "PR" ? payload.documents:"",
          v_cfdi: rootState.auth.user.VTWEG == "PR" ? payload.v_cfdi:"",
          select_metodo: rootState.auth.user.VTWEG == "PR" ? payload.select_metodo:"",
          select_v_pay:  rootState.auth.user.VTWEG == "PR" ? payload.select_v_pay:"",
          select_use_cfdi:  rootState.auth.user.VTWEG == "PR" ? payload.select_use_cfdi:"",
          schedule_orders: payload.schedule_orders,
          reminder_date: payload.schedule_date,
        })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },

    async updateMaterialCredit({rootState, dispatch}, payload){

    const response =   await axios.post(rootState.auth.user.VTWEG == "PR" ? '/up-material-credit-pr':'/up-material-credit',{
        orderId: payload.orderId,
        material: payload.sku,
        credit: payload.credit,
      });
    
      await dispatch("loadCartUpdate",payload);
      return response.data;
      
  },
  async loadCartUpdate({ commit, rootState }, payload) {
    const response = await axios
        .post(rootState.auth.user.VTWEG == "PR" ? "/get-material-info-pr":"/getMaterialInfo", {
          code: payload.sku,
          units: payload.quantity,
          customer: payload.custumer,
          VKORG: rootState.auth.user.VKORG,
          VTWEG: rootState.auth.user.VTWEG,
          VKBUR: rootState.auth.user.VKBUR,
          doctype: payload.doctype,
          v_credit: payload.credit ? 1:0,
        });
        commit("UPDATE_ITEM", response.data);
        return response.data;
  },

  async updateOrderName({rootState, commit, state}, payload){

    const response =   await axios.post('/save-order-name',{
      orderId: state.orderId,
      orderName: payload.orderName,
      channel: rootState.auth.user.VTWEG == "DT" ? true:false
    });
    
      commit("SET_ORDER_NAME", response.data.name)
      return response.data.name;
      
  },
    async getProductImages({state, commit}, productIndex) {
      const product = state.cart[productIndex];

      const {data} = await axios.get('https://bancodeimagenes.iusa.com.mx/api/listDropboxNew',
        {
          params: {code: product.codigo_material, quant: 0, view: 0},
          headers: {
            Authorization: 'Bearer token',
            'Content-Type': 'application/json',
            'api-key': '$2y$10$PtV2yrGrt/CwoVlqGDroiOyoUSq581Pq.6NVyAX2vQJUBdE21gxKC'
          }
        }
      );

      const imagesResponse = data;
      // Remove last item of the array, it is the metadata of the response
      const images = imagesResponse.splice(0, imagesResponse.length - 1).map((image) => ({
        title: image.name, 
        description: product.nombre_material,
        src: image.path
      }));
      
      if(images){
        commit('SET_PRODUCT_IMAGES', {productIndex, images});
      }

      return images;
    },
  }


};
  
const getLowerIva = (customerIva, productIvaValue) => {
  const productIva =  (productIvaValue == 0 || productIvaValue == 2 || productIvaValue == 3 || productIvaValue == 6) ? 0 : 0.16;
  const lowerIva = productIva < customerIva ? productIva : customerIva;
  return lowerIva;
}
import { render, staticRenderFns } from "./NewShoppingCartPR.vue?vue&type=template&id=6a790ef4&scoped=true&"
import script from "./NewShoppingCartPR.vue?vue&type=script&lang=js&"
export * from "./NewShoppingCartPR.vue?vue&type=script&lang=js&"
import style0 from "../../../public/css/shoppingpr.css?vue&type=style&index=0&id=6a790ef4&prod&scoped=true&lang=css&"
import style1 from "./NewShoppingCartPR.vue?vue&type=style&index=1&id=6a790ef4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a790ef4",
  null
  
)

export default component.exports
<template>
  <v-data-table
    v-model="selectedReminders"
    :headers="tableHeaders"
    :items="items"
    :search="search"
    :loading="loading"
    :item-key="itemKey"
    :mobile-breakpoint="0"
    :height="height"
    :show-select="!noShowSelect"
    :itemClass="rowTableClass"
    :hide-default-footer="!paginated"
    :disable-pagination="!paginated"
    :footer-props="{ itemsPerPageText: 'Mostrar en grupo de', itemsPerPageAllText: 'Todos', pageText: '{0}-{1} de {2}' }"
    :custom-filter="customSearch"
    sort-by="date"
    no-data-text="No hay datos disponibles"
    class="card-shadow rounded-0"
    loading-text="Cargando..."
    dense
    @item-selected="emitSelection"
  >
    <template v-slot:[`header.data-table-select`]>Ir a carrito</template>
    
    <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
      <v-simple-checkbox
        :disabled="loading || !item.isSelectable"
        :value="isSelected"
        @input="select($event)"
      />
    </template>

    <template v-slot:[`item.requiredQty`]="{ item }">
      <pill-status :status="item.status" class="my-1">{{ formatNumber(item.requiredQty, '') }}</pill-status>
    </template>

    <template v-slot:[`item.customer`]="{ item }">
      <div>
        ({{ item.customer.code }}) - {{ item.customer.name }}
      </div>
    </template>

    <template v-slot:[`item.discountPrice`]="{ item }">
      <div>
      $ {{ formatNumber(item.discountPrice, '') }}
      </div>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <action-btn type="delete" @on-click="$emit('on-remove-item', item)" />
    </template>
  </v-data-table>
</template>

<script>
import ActionBtn from '../ActionBtn.vue';
import PillStatus from '../PillStatus.vue';
import numbersFormats from '@/mixins/numbersFormats';

export default {
  name: 'RemindersTable',

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      required: true,
    },

    search: {
      type: String,
      default: '',
    },

    itemKey: {
      type: String,
      default: 'id',
    },

    height: {
      type: String,
      default: '320px',
    },

    hideHeaders: {
      type: Array,
      default: () => []
    },

    noShowSelect: Boolean,
    hideDeleteAction: Boolean,
    loading: Boolean,
    paginated: Boolean
  },

  components: { ActionBtn, PillStatus },

  mixins: [numbersFormats],

  data: () => ({
    headers: [
      { text: 'ID Rec.', sortable: false, value: 'id' },
      { text: 'Fecha', value: 'date' },
      { text: 'Fecha programado', value: 'dateProgrammed' },
      { text: 'Gerente Regional', align: 'start', value: 'regManager' },
      { text: 'Gerente', align: 'start', value: 'manager' },
      { text: 'Vendedor', align: 'start', value: 'seller' },
      { text: 'Cliente', align: 'start', value: 'customer' },
      { text: 'Ord. compra', value: 'orderConcept' },
      { text: 'Código', sortable: false, value: 'materialId' },
      { text: 'Nombre', sortable: false, value: 'materialName' },
      { text: 'Precio c/ Descuento', sortable: false, value: 'discountPrice' },
      { text: 'Unidades', sortable: false, value: 'requiredQty' },
      { text: '', value: 'data-table-select' },
      { text: 'Eliminar', sortable: false, value: 'actions' },
    ],

    selected: [],
    searchReminder: '',
  }),

  methods: {
    emitSelection(item, value) {
      setTimeout(() => {
        this.$emit('item-selected', item, value);
      }, 100);
    },

    rowTableClass(item) {
      let classes = '';
      if (this.noShowSelect) return '';

      if (!item.isSelectable) classes += ' cursor-not-allowed';
      if (item.noMatch || item.hasError) classes += ' selection-not-allowed'; // Makes column when the red
      
      return classes;
    },

    customSearch (value, search, item) {
      if (value == null || search == null) return false;

      const searchLowerCase = search.toString().toLocaleLowerCase();
      const customer = `${item?.customer?.code || '' } ${item?.customer?.name || ''}`

      const searchByValue = value.toString().toLocaleLowerCase().indexOf(searchLowerCase) !== -1;
      const searchByCustomer = customer.toString().toLocaleLowerCase().indexOf(searchLowerCase) !== -1; // As customer is a custom column vue default filter doesnt work

      return searchByCustomer || searchByValue;
    }
  },

  computed: {
    selectedReminders: {
      set(newValue) {
        this.$emit('input', newValue);
      },

      get() {
        return this.value;
      },
    },

    tableHeaders () {
      const hideHeaders = this.noShowSelect ? ['data-table-select'] : [];
      hideHeaders.push(...this.hideHeaders);
      
      if (hideHeaders.length > 0) {
        return this.headers.filter(h => !hideHeaders.includes(h.value))
      } else {
        return this.headers;
      }
  },
  },
};
</script>

<style scoped>
.v-data-table >>> .v-data-table-header {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
  background-color: rgb(242, 242, 242);
}

.v-data-table >>> .v-data-table-header > tr > th {
  color: black !important;
}

.v-data-table >>> .v-data-table-header > tr > th {
  border-bottom: 2px solid rgb(112, 112, 112) !important;
}
</style>

<style>
.selection-not-allowed,
.selection-not-allowed:hover {
  background-color: rgb(243, 220, 220) !important;
}
</style>
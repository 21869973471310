import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import diary from "./diary";
import ordercsv from "./ordercsv";
import ordercart from "./ordercart";
import quotecart from "./quoter-cart";
import administrator from "./administrator";
import livetest from "./livetest";
import promissorynotes from "./seller/promissorynotes";
import printer from "./printer";
import cancellableRequest from './cancellableRequest';
import selectedUser from './selectedUser';
import customersrequest from "./credit/customersrequest";
import creditnotes from "./credit/promissorynotes";
import loader from './loader';
import modalStepsAfterLogin from "./modalStepsAfterLogin";
import reminders from "./reminders";
import userNotification from "./userNotification";
import {updateData} from "./updateData";
import { updatePassword } from "./updatePassword";
import coordinatesRecords from "./coordinatesRecords";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    diary,
    ordercsv,
    ordercart,
    quotecart,
    administrator,
    livetest,
    promissorynotes,
    printer,
    cancellableRequest,
    selectedUser,
    customersrequest,
    creditnotes,
    loader,
    modalStepsAfterLogin,
    reminders,
    userNotification,
    updateData,
    updatePassword,
    coordinatesRecords
  },
});

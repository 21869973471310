<template>
  <shopping-card title="Destinario mercancia">
    <template v-if="!loading">
      <v-autocomplete
        v-model="selectedDestination"
        :items="destinations"
        :item-text="formatDestinyText"
        return-object
        dense
        rounded
        placeholder="Selecciona el destino de compra"
        class="w-full"
      />
    </template>
    <template v-else>
      <v-progress-linear indeterminate color="cyan" />
    </template>

    <!-- Map destination launcher -->
    <template slot="header-right">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" x-small @click="launchMapModal">
            <v-icon :color="!selectedDestination ? 'grey' : '#30009C'">
              mdi-compass-outline
            </v-icon>
          </v-btn>
        </template>
        <span>{{
          !selectedDestination
            ? 'Selecciona un destino de compra'
            : 'Ver destino en el mapa'
        }}</span>
      </v-tooltip>
    </template>

    <destination-map-modal
      v-model="showMap"
      :destinationId="selectedDestination?.KUNNR || null"
    />
  </shopping-card>
</template>

<script>
import ShoppingCard from './ShoppingCard.vue';
import DestinationMapModal from '@/components/modals/DestinationMapModal.vue';

export default {
  name: 'DestinationSelector',

  components: { ShoppingCard, DestinationMapModal },

  props: {
    destinations: {
      type: Array,
      required: true,
    },

    // Type String is allowed due to parent inconsistency. The destination is initialized as a string but used as an object.
    value: {
      type: [Object, String],
      default: null,
    },

    loading: Boolean,
  },

  data() {
    return {
      showMap: false,
    };
  },

  methods: {
    formatDestinyText(item) {
      return `${item.KUNNR} ${item.STRAS} ${item.ORT01} ${item.PSTLZ}`;
    },

    launchMapModal() {
      if (this.selectedDestination) this.showMap = true;
    },
  },

  computed: {
    selectedDestination: {
      set(newValue) {
        this.$emit('input', newValue);
      },

      get() {
        return this.value;
      },
    },
  },
};
</script>

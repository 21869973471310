<template>
  <v-data-table
    dense
    :sort-by="['fecha']"
    :headers="tableHeaders"
    :items-per-page="10"
    :items="items"
    :search="search"
    :sort-desc="[true]"
    item-key="id"
    loading-text="Cargando información..."
    no-data-text="No hay datos disponibles"
    :footer-props="{
      itemsPerPageText: 'Mostrar en grupo de',
      itemsPerPageAllText: 'Todos',
      pageText: '{0}-{1} de {2}',
    }"
    class="elevation-2"
  >
    <template v-slot:[`item.pedido`]="{ item }">
      <a @click="$emit('onOrderDetail', item)" style="color: blue">
        {{ item.pedido }}
      </a>
      <v-tooltip bottom v-if="item.auart == 'PSPO'">
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <img
              class="ml-2"
              src="@/assets/img/indicators_invoice/POP.svg"
              style="width: 20px"
              alt=""
            />
          </span> </template
        ><span>Bono POP</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.entrega`]="{ item }">
      <a @click="$emit('onDeliveryDetail', item)" style="color: blue">
        {{ item.entrega }}
      </a>
    </template>

    <template v-slot:[`item.fecha_pedido`]="{ item }">
      <v-tooltip bottom v-if="item.fecha_picking != '0000-00-00'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"
            >mdi-package-variant-closed-check
          </v-icon>
        </template>
        <span>{{ item.fecha_picking }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.fecha_factura != '0000-00-00'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-receipt-text </v-icon>
        </template>
        <span>{{ item.fecha_factura }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="item.fecha_actual_inicio_trans != '0000-00-00'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-truck </v-icon>
        </template>
        <span
          >{{ item.fecha_actual_inicio_trans }} -
          {{ item.fecha_actual_fin_trans }}</span
        >
      </v-tooltip>
      <v-tooltip bottom v-if="item.fecha_acuse_factura != '0000-00-00'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-account </v-icon>
        </template>
        <span>{{ item.fecha_acuse_factura }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.pdf_xml`]="{ item }">
      <a v-if="item.uuid != ''" @click="$emit('onPDFClick', item.pdf_xml, 1)">
        <v-icon dense large color="red"> mdi-file-pdf-box </v-icon></a
      >
      <a
        href="#"
        v-if="item.uuid != ''"
        @click="$emit('onPDFClick', item.pdf_xml, 2)"
      >
        <v-icon dense large color="blue"> mdi-file-xml-box </v-icon></a
      >
      <a
        href="#"
        v-if="item.uuid != ''"
        @click="$emit('onEmailPDFClick', item)"
      >
        <v-icon dense large color="blue">
          mdi-email-arrow-right-outline
        </v-icon></a
      >
      <p href="#" v-if="item.uuid == '' && item.pdf_xml != ''">
        <v-icon dense color="red"> mdi-alert </v-icon
        ><span> {{ item.denrfbsk }}</span>
      </p>
    </template>
    <template v-slot:[`item.pay_complement`]="{ item }">
      <a
        v-if="item.pay_complement != ''"
        @click="$emit('onPDFClick', item.pay_complement, 1)"
      >
        <v-icon dense large color="red"> mdi-file-pdf-box </v-icon></a
      >
      <a
        href="#"
        v-if="item.pay_complement != ''"
        @click="$emit('onPDFClick', item.pay_complement, 2)"
      >
        <v-icon dense large color="blue"> mdi-file-xml-box </v-icon></a
      >
      <a
        href="#"
        v-if="item.pay_complement != ''"
        @click="$emit('onEmailComClick', item)"
      >
        <v-icon dense large color="blue">
          mdi-email-arrow-right-outline
        </v-icon></a
      >
    </template>
  </v-data-table>
</template>
<script>
import moment from "moment";

const headerHistory = [
  { text: "Fecha", align: "start", value: "fecha" },
  { text: "Consec", align: "start", value: "consec" },
  { text: "Usuario", align: "start", value: "user_create" },
  { text: "Orden de compra", value: "orden" },
  { text: "Pedido", sortable: false, value: "pedido" },
  { text: "Entrega", sortable: false, value: "entrega" },
  { text: "Factura", sortable: false, value: "factura" },
  { text: "Estatus", sortable: false, value: "estatus" },
  { text: "Tracking", sortable: false, value: "fecha_pedido" },
  { text: "PDF/XML", sortable: false, value: "pdf_xml" },
  { text: "Complemento de Pago", sortable: false, value: "pay_complement" },
];

export default {
  name: "OrderHistoryTable",
  props: {
    value: {
      type: Array,
      default: () => [],
    },

    items: {
      type: Array,
      required: true,
    },

    search: {
      type: String,
      default: "",
    },

    height: {
      type: String,
      default: "320px",
    },

    hideHeaders: {
      type: Array,
      default: () => [],
    },
    loading: Boolean,
    paginated: Boolean,
  },
  data() {
    return {
      tableHeaders: headerHistory,
      startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
    };
  },
};
</script>
<style scoped>
.v-data-table >>> .v-data-table-header {
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029 !important;
  background-color: rgb(242, 242, 242);
}

.v-data-table >>> .v-data-table-header > tr > th {
  color: black !important;
}

.v-data-table >>> .v-data-table-header > tr > th {
  border-bottom: 2px solid rgb(112, 112, 112) !important;
}
</style>

<template>
  <div id="contenedor" ref="ShoppingCart">
  <v-container fluid class="grey lighten-5 shopping-cart-card" ref="printResultSellerView">
    <blocked-by-backorders-alert v-if="userIsBlockedByBackorders" class="mt-5" />
  <div class="grid-container mt-25">
    <div class="type-2 mt-5">
      <v-form @submit.prevent="saveOrderName" >
          <div class="d-flex align-items-baseline mt-1">
          <span class="w-40 text-left font-size-9"
            ><b>Ingrese Orden de Compra:</b></span
          >
          <v-text-field
            dense
            solo
            class="text-ped pt-0 mt-0 font-size-8 mt-7 mb-1"
            v-model="orderSet"
            required
          ></v-text-field>
        </div>
      </v-form>
      <destination-selector  
        v-model="destiny"
        :destinations="destinations" 
        :loading="destinations.length == 0"
      />
    </div>
    <div class="search mt-5">
    <shopping-card :showTitle="false" class="w-full">
      <span class="w-40 text-left font-weight-bold font-size-9 mt-2"
            >Búsqueda</span>
        <v-form @submit.prevent="addItemToCart" ref="searchMaterialForm" >
          <div class="d-flex align-items-baseline">
            <span class="w-40 text-left font-weight-bold font-size-9">Código de material-SKU/Descripción:</span>
            <v-text-field dense solo autofocus ref="input" class="text-ped pt-0 mt-0 w-40" v-model="searchForm.sku" :disabled="showNameField"
              required type="text" :rules="[(v) => !!v || 'Este campo es requerido']"
              v-on:keyup.enter="onEnterCode()">
              <template v-slot:append>
                <v-btn v-if="searchForm.sku" icon @click="clearSku">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <div id="resultados"></div>
          </div>

            <div class="d-flex align-items-baseline">
              <span class="w-40 text-left font-weight-bold font-size-9">Unidades:</span>
              <v-text-field dense solo class="text-ped pt-0 mt-0 w-40" v-model="searchForm.quantity" required
                :rules="[(v) => !!v || 'La cantidad es requerida', (v) => !!/^[0-9]+$/.test(v) || 'Sólo se admiten números']"
                ref="units"
               ></v-text-field>
              <div class="w-20"></div>
            </div>
          
          <div v-if="showNameField">
            <div class="d-flex d-inline-block">
              <div style="width: 60px" class="mr-1">
              <img class="w-full" :src="searchForm.url" alt="" />
            </div> 
            <v-text-field v-model="searchForm.name" label="Nombre" solo readonly></v-text-field>
            </div> 
          </div>
          <div class="mt-2">
            <v-btn type="submit" :disabled="blockItemCart || blockViewByBackordersPending" class="button-green px-16 text-white font-weight-black py-5" rounded-md>
              <span>Agregar a carrito</span>
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </div>
        </v-form>
      </shopping-card>
    </div>
    <div class="data-reng justify-center">
      <div class="align-center justify-center" >
       <span>1 al 1 de Productos</span><br>
       <span>Renglonaje Pedido / Trimestre</span><br>
       <span>(1/427)</span><br><br>
       <span>
        <v-badge
          color="info" 
          :content="counItem"
          :value="counItem"
          overlap
          >
          <v-icon large color="black">mdi-cart-variant</v-icon>
        </v-badge>
       </span>
      </div>
    </div>
    <div class="custumer-cred mt-5">
      <v-card-subtitle class="pa-lg-2">
          <p style="text-align: center">
            LIMITE DE CRÉDITO:
            <span style="font-weight: 800">${{ formatPrice(customerInfo.KLIMK) }}</span>
          </p>
          <v-progress-linear v-model="porcentaje" height="25">
            <strong style="color: #fff">{{ porcentaje }}%</strong>
          </v-progress-linear>
          <v-row>
            <v-col cols="6" sm="12" md="6" class="mt-3">
              <span style="font-size: 12px"
                >SALDO AL CORTE:
                <span style="font-weight: 800; font-size: 14px"
                  >${{ formatPrice(customerInfo.SKFOR) }}</span
                ></span
              >
            </v-col>
            <v-col cols="6" sm="12" md="6" class="mt-3">
              <span style="font-size: 12px"
                >CREDITO DISPONIBLE:
                <span style="font-weight: 800; font-size: 14px"
                >${{ formatPrice(aviable.toFixed(2))}} 
              </span></span
              >
              <br>
              <span style="font-size: 12px" class="mt-1">
                CREDITO DISPONIBLE PEDIDO ACTUAL: 
              <span style="font-weight: 800; font-size: 14px"
                >${{ formatPrice(totalCred.toFixed(2)) }} 
              </span>
            </span>
            </v-col>
          </v-row>
          <p></p>
        </v-card-subtitle>
    </div>
    <div class="order-plus">
        <v-btn small block 
        class=" text-black font-weight-bold font-size-10 shadow-b"
        @click="cleanOrder"
          >
          Limpiar Pedido 
          <span class="ml-2 icoiusa-plus-circle"></span>
        </v-btn>
        <v-btn small  
        block
        class="mt-5 text-black font-weight-bold font-size-10 shadow-b"
        @click="goHistoryOrdes"
          >
          Historial Pedidos
          <span class="ml-2 icoiusa-check-circle"></span>
          <span class=" icoiusa-group-circle"></span>
        </v-btn>
        <v-btn small  
        block
        class="mt-5 text-black font-weight-bold font-size-10 shadow-b"
        @click="goOrderCSV"
          >
          <span>Cargar Archivo</span>
          <span class="ml-2 icoiusa-cloud-upload"></span>
        </v-btn>
    </div>
    <div class="promotions">
        <v-btn small solo 
        class="text-black font-weight-bold font-size-10 shadow-b-prom w-full"
          >
          <span>Promociones</span>
          <span class="ml-2 icoiusa-asterisk-circle-outline"></span>
        </v-btn>
        <v-btn small solo class="mt-5 text-black font-weight-bold font-size-10 shadow-b-prom w-full"
          >
          <span>Liquidaciones</span>
          <span class="ml-2 icoiusa-tag"></span>
        </v-btn>
        <v-btn large solo class="mt-5 text-white font-weight-bold font-size-10  button-prom w-full"
          >
          <span>MÁS DE 4,200 PRODUCTOS</span>
        </v-btn>
        <v-btn large solo class="mt-5 text-white font-weight-bold font-size-10  button-pnc w-full"
          >
          <span>PRODUCTOS NUEVOS PNC</span>
        </v-btn>
    </div>
    </div>
    <v-row>
      <v-col cols="12" class="mt-5">
        <span style="font-weight:bold; color: grey; font-size: 16px; text-align: right;" v-if="doctype == 'PSMA'">Peido de Apoyo a la Marca</span>
        <span style="font-weight:bold; color: grey; font-size: 16px;" v-if="doctype == 'PSNW'">Pedido de Productos Nuevos PNC</span>
        <span style="font-weight:bold; color: grey; font-size: 16px; align-self: right;" v-if="doctype == 'PSPO'">Pedido de articulos Pomocionales POP</span>
        <span style="font-weight:bold; color: grey; font-size: 16px;" v-if="doctype == 'PSDR'">Pedido de Tienda Nueva</span>
        <v-simple-table>
          <thead class="table-header">
            <tr>
              <th class="table-header text-center">Producto</th>
              <th class="table-header text-center">Genética</th>
              <th class="table-header text-center">Cantidad</th>
              <th class="table-header text-center">Empaque</th>
              <th class="table-header text-center">
                Unidad de medida de venta
              </th>
              <th v-if="!this.$hasAnyRole(['Customer','CustomerPR'])" class="table-header text-center">Existencia</th>
              <th v-if="!this.$hasAnyRole(['Customer','CustomerPR'])" class="table-header text-center">Existencia CDPT</th>
              <th class="table-header text-center">
                Promedio mensual de compra*
              </th>
              <th class="table-header text-center">
                Días solicitados de inventario*
              </th>
              <th class="table-header text-center">Precio Lista</th>
              <th class="table-header text-center">Precio C/ descuento</th>
              <th class="table-header text-center">Importe Total</th>
              <th class="table-header"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in statecart" :key="`product-${i}`">
              <td class="h-auto d-flex flex-nowrap align-center">
                <!-- PRODUCT IMAGE THUMBNAIL  -->
                <div style="min-width: 70px; min-height: 100%;">
                  <v-progress-circular
                    v-if="!item.image_url"
                    indeterminate
                    color="primary"
                  />
                  <v-img
                    v-else
                    :src="item.image_url"
                    max-height="100%"
                    max-width="70px"
                    class="cursor-pointer"
                    contain
                    @click="openProductImageGallery(item, i)"
                  />
                </div>
                
                <div class="d-flex flex-column">
                  <span class="text-left font-size-8">
                    <special-discount-icon
                     :discount="item"
                     />
                </span>
                <p
                  class="text-left font-size-8 mb-1" 
                  style="max-width: 150px; word-wrap: break-word"
                >
                  {{ item.nombre_material }}
                </p>
                <div class="d-flex align-center">
                    <v-btn 
                      icon 
                      @click="
                        product = item;
                        isProductTechnicalShetsDialogOpen = true
                    ">
                      <v-icon color="red red-darken-4">
                        mdi-folder-outline
                      </v-icon>
                    </v-btn>
                    <span class="text-left font-size-8">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            :class="item.validacion"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>{{ item.stock_label }}</span>
                      </v-tooltip>
                    </span>
                  </div>
              </div>              
            </td>
            <td class="text-center">
              <span class="font-size-8">{{ item.material_type }}</span>
            </td>
            <td class="text-center" style="width: 90px; font-size: 10px;">
              <v-text-field class="font-size-8 mb-0" dense hide-details single-line v-model="item.u_pedidas" @keydown.enter.prevent="editQuantity(item, item.u_pedidas)">
              </v-text-field>
              <br />
              <!---<a class="text-black" @click="editQuantity(item)">
                <span class="font-size-8"
                  >{{ formatInt(item.u_confirm) }}
                  </span
                >
              </a>-->
              <p class="font-size-8">{{
                    item.recordatorios != 0
                      ? `BACKORDER ${item.recordatorios}`
                      : ""
                  }}</p>
            </td>
            <td>
              <span class="font-size-8">{{ item.empaque }}</span>
            </td>
            <td>
              <span class="font-size-8">{{ item.unidad_medida }}</span>
            </td>
            <td v-if="!$hasAnyRole(['Customer','CustomerPR'])">
              <span class="font-size-8">{{ format(item.existencia) }}</span>
            </td>
            <td v-if="!$hasAnyRole(['Customer','CustomerPR'])">
              <span class="font-size-8">{{ format(item.existencia_cdpt) }}</span>
            </td>
            <td>
              <span class="font-size-8">{{ item.PMCOM }}</span>
            </td>
            <td>
              <span class="font-size-8">{{ item.DSINV }}</span>
            </td>
            <td>
              <span class="font-size-8"
                >$ {{ format(item.precio_lista) }}</span
              >
            </td>
            <td>
              <span v-if="item.recordatorios == 0" class="font-size-8"
                >$ {{ format(item.precio_con_descuento) }}</span
              >
              <span v-else class="font-size-8"> $ {{format(item.importe_desciento)}}</span>
            </td>
            <td>
              <b class="font-size-8">$ {{ format(item.importe_producto) }}</b>
            </td>
            <td>
              <button class="icon-button" @click="deleteItem(item)">
                <img src="/img/trash-can-outline.svg" alt="" />
              </button>
            </td>
          </tr>
          <tr v-if="statecart.length <= 0">
            <td colspan="12" class="text-center">
              No hay productos en el carrito
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
  <loading-bar  v-model="dialogLoading"></loading-bar>
  <!--AQUI DEBAJO DEL CARRITO--->
  <div class="mt-8 footer-container">

  <div class="btn-spc-order">
          <div v-if="doctype !== 'PSDT' " class="mb-5">
            <v-btn block class="button-block" @click="goSpecialOrder('PSDT')">
             Convertir a Pedido Normal
             <span class="ml-2 icoiusa-checkbox-marked"></span>
            </v-btn>
          </div>
          <!--<v-menu open-on-hover offset-x :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" block 
              v-bind:class="doctype != 'PSMA' ? 'button-block':'button-block-disbled'"
              @click="doctype != 'PSMA' ? goSpecialOrder('PSMA'): ''"
              >
                Pedido apoyo de marca
                <span class="ml-2 icoiusa-axis-z-rotate"></span>
              </v-btn>
            </template>
            <order-brand-sup-val :customerInfo="customerInfo"></order-brand-sup-val>
          </v-menu>-->
          <v-menu open-on-hover offset-x :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" block 
              v-bind:class="doctype != 'PSNW' ? 'button-block':'button-block-disbled mt-5'"
              @click="doctype != 'PSNW' ? goSpecialOrder('PSNW'): ''">
                Convertir a Pedido de productos nuevos PNC
                <span class="ml-2 icoiusa-two-arrows"></span>
              </v-btn>
            </template>
            <special-order-val-psnw :customerInfo="customerInfo"></special-order-val-psnw>
          </v-menu>
          <v-menu open-on-hover offset-x :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" block 
              v-bind:class="doctype != 'PSPO' ? 'button-block mt-5':'button-block-disbled mt-5'"
              @click="doctype != 'PSPO' ? goSpecialOrder('PSPO'): ''"
              >
                Convertir a Pedido de artículos promocionales (POP)
                <span class="ml-2 icoiusa-shrink2"></span>
              </v-btn>
            </template>
            <special-order-val-pop :customerInfo="customerInfo"></special-order-val-pop>
          </v-menu>
    </div>
    <div class="btn-spc-order-2">
          <shopping-card :showTitle="false" style="background-color: #67C3D0;" class="w-full pt-0 mt-0">
            <v-switch dense v-model="schedule_orders" color="primary" class="d-inline-block pt-0 mt-0 pa-0 pb-0 mb-0"
              hide-details>
              <template v-slot:label>
                <span label-position="left" class="font-size-9 text-white font-weight-bold">Pedido Programado</span>
              </template>
            </v-switch>
          </shopping-card>
          <v-menu
                v-if="schedule_orders"
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="schedule_date"
                    label="Fecha Programada"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                    <v-date-picker
                    v-model="schedule_date"
                    @input="closeDateMenu1"
                    :min-date='minDate'
                    :is-required="true"
                    ></v-date-picker>
                </v-menu>
          <!--<v-menu open-on-hover offset-x :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" block 
              v-bind:class="doctype != 'PSDR' ? 'button-block mt-4':'button-block-disbled mt-4'"
              @click="doctype != 'PSDR' ? goSpecialOrder('PSDR'): ''"
              >
                Convertir a Pedido de productos de reorden PPR
                <span class="ml-2 icoiusa-horizontal-rotate"></span>
              </v-btn>
            </template>
            <special-order-val-psdr :customerInfo="customerInfo"></special-order-val-psdr>
          </v-menu>-->
          <!--<v-menu open-on-hover offset-x :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" block 
               :disabled="sellerOrderAviable"
               v-bind:class="doctype != 'PSTN' ? 'button-block mt-4':'button-block-disbled mt-4'" 
               @click="doctype != 'PSTN' ? goSpecialOrder('PSTN'):''">
                Convertir a Pedido Tienda Nueva
                <span class="ml-2 icoiusa-axis-x-rotate"></span>
              </v-btn>
            </template>
            <SpecialOrderValPstn :customerInfo="customerInfo"></SpecialOrderValPstn>
          </v-menu>-->
    </div>
    <div class="btn-dashboard">
      <v-btn disabled block class="button-block mt-1">
        Convertir a Pedido de productos descontinuados con descuento de liquidación
            <span class="ml-2 icoiusa-loop2"></span>
          </v-btn>
    </div>
    <div class="btn-total">
      <div
        class="w-full text-left py-1 new-card-title new-card-pink d-flex justify-space-around"
      >
        <span class="w-50 text-center">Subtotal del pedido:</span>
        <span class="text-center"
          >$ {{ formatPrice(statesubtotal.toFixed(2)) }}</span
        >

      </div>
      <div
        class="w-full text-left py-1 new-card-title new-card-pink d-flex justify-space-around"
      >
        <span class="w-50 text-center">I.V.A.</span>
        <span class="text-center"
          >$ {{ formatPrice(stateiva.toFixed(2)) }}</span
        >
      </div>
      <div
        class="w-full text-left py-1 new-card-title new-card-red d-flex justify-space-around"
      >
        <span class="w-50 text-center">Total con IVA MXN</span>
        <span class="text-center"
          >$ {{ formatPrice(statetotal.toFixed(2)) }}</span
        >
      </div>

      <v-btn
        :class="isButtonDisabled == true || userIsBlockedByBackorders? 'button-green-process w-full text-white font-weight-bold font-size-8 mt-8': 'button-green w-full text-white font-weight-bold font-size-8 mt-8'"
        @click="checkBackOrder" 
        :loading="seendingOrder"
        :disabled="isButtonDisabled || userIsBlockedByBackorders"
        >
        <span class="text-white" style="font-size: 12px;" v-if="isButtonDisabled || userIsBlockedByBackorders">Compra en Proceso <v-icon>mdi-checkbox-multiple-marked-circle</v-icon></span>
        <span v-else>CONFIRMAR PEDIDO<v-icon class="ml-3">mdi-checkbox-multiple-marked-circle</v-icon></span>
        <span ></span>
      </v-btn>
    </div>
    <div class="btn-save align-items-baseline">
      <v-row class="mt-0">
        <v-col md="4">
          <v-btn small block class="pt-0 mt-0 ml-1 text-black font-weight-bold font-size-10 shadow-b" @click="goTo('/')">
            Carátula cliente
            <span class="ml-2 icoiusa-chart-box-outline"></span>
          </v-btn>
        </v-col>
        <v-col md="4">
          <v-btn small block class="pt-0 mt-0 ml-3 text-black font-weight-bold font-size-10 shadow-b" @click="generateFiles">
            Generar Archivo
            <span class="ml-2 icoiusa-file-download"></span>
          </v-btn>
        </v-col>
        <v-col md="4">
          <v-btn small block class="pt-0 mt-0 ml-3 text-black font-weight-bold font-size-10 shadow-b" @click="createQuoter">
            Convertir a Coti.
            <span class="ml-1 icoiusa-arrow-left-right"></span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
  <v-row>
    <v-col>
      <p class="m-0 p-0 text-left">* Última actualización {{ actualDate }}</p>
    </v-col>
    <v-col>
      <p class="m-0 p-0 text-right">
        Información confidencial Prohibida su reproducción o divulgaciòn total
        o parcial, así como su uso o aprovechamiento sin autorización escrita
        de IUSA.
      </p>
    </v-col>
  </v-row>
  <v-dialog v-model="quantityDialog.show" persistent max-width="700">
    <v-card>
      <v-card-title>
        <span class="text-h5">Editar cantidad</span>
      </v-card-title>
      <v-card-text>
        <div class="d-flex align-items-baseline">
          <span class="w-30 text-left font-weight-bold font-size-9"
            >Producto:</span
          >
          <v-text-field
            dense
            solo
            class="text-ped pt-0 mt-0 w-40"
            required
            readonly
            v-model="quantityDialog.name"
          ></v-text-field>
        </div>
        <div class="d-flex align-items-baseline">
          <span class="w-30 text-left font-weight-bold font-size-9"
            >Nueva cantidad:</span
          >
          <v-text-field
            dense
            solo
            class="text-ped pt-0 mt-0 w-40"
            required
            type="number"
            min="1"
            v-model="quantityDialog.quantity"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="cancelQuantity">Cancelar</v-btn>
        <v-btn color="primary" @click="saveQuantity">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
   <!-- Dialog loading -->
  <v-dialog v-model="dialogBackOrder" persistent  width="550" min-width="550" scrollable>
    <v-card>
      <div class="w-full bg-black d-flex justify-content-between align-center px-4">
        <span class="text-white d-flex align-center font-weight-bold">Backorder</span>
        <v-btn icon small style="bg-gray" @click="dialogBackOrder = false">
          <v-icon class="text-white font-size-8">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="w-9 bg-white d-flex flex-wrap">
        <h4 class="w-full mt-4 font-weight-bold" style="color: grey;">CONFIRMAR BACKORDER</h4>
        <span class="solid_line"></span>
        <span class="text-title-bk font-weight-bold mt-3 mb-5">Seleccione los backorders confirmados</span>
        <!-- Verifica si la lista de items está vacía -->
        <result-item-backorder class="mr-5 ml-5" v-for="(item, i) in statecart" :key="i.codigo_material" :item="{            
          materialCode: item.codigo_material.slice(-6),
          name: item.nombre_material,
          sku: item.codigo_material.slice(-6),
          reminder: item.reminder_check,
          recordatorios: item.recordatorios,
          precio_lista: item.precio_lista}">
          <div style="width: 60px" class="mr-1">
            <img class="w-full" :src="item.image_url" alt="" />
          </div>
        </result-item-backorder>
      </div>
      <v-layout justify-center>
      <v-card-actions class="justify-center">
        <v-spacer></v-spacer>   
        <v-btn class="button-backorder-text" @click="dialogBackOrder = false">
          CANCELAR
        </v-btn>
        <v-btn
        class="button-green w-full text-white font-weight-bold font-size-8 mt-8"
        :loading="seendingOrder"
        :disabled="isButtonDisabled || seendingOrder"
        @click="orderGenerateCredit"

        >
        <span class="text-white" style="font-size: 12px;" v-if="isButtonDisabled">Compra Bloqueada <v-icon>mdi-cancel</v-icon></span>
        <span v-else>CONFIRMAR PEDIDO<v-icon class="ml-3">mdi-checkbox-multiple-marked-circle</v-icon></span>
        <span ></span>
      </v-btn>
      </v-card-actions>
    </v-layout>
    </v-card>
  </v-dialog>
  <!-- Dialog loading -->
  <v-dialog v-model="dialogSpecilaOrder"       
    scrollable
    width="auto">
    <v-card round elevation="0">
      <v-btn class="mx-2 mt-2" fab x-small elevation="0" style="align-self: end;" @click="dialogSpecilaOrder=false">
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
      <special-order-dialog-val :paramSelected="param_selected" :customerInfo="customerInfo" :cart="statecart" :statetotal="statetotal" :order_id="orderId" :idq="idq"></special-order-dialog-val>
    </v-card>
  </v-dialog>
  <!-- Dialog loading -->
  <v-dialog v-model="searchProductDialog" :persistent="searchProductsLoader" width="550" min-width="550">
    <v-card>
      <div class="w-full bg-black d-flex justify-content-between align-center px-4">
        <span class="text-white d-flex align-center">Productos</span>
        <v-btn v-if="!searchProductsLoader" icon small style="bg-gray" @click="searchProductDialog = false">
          <v-icon class="text-white font-size-8">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="w-9 bg-white d-flex flex-wrap">
        <h4 class="w-full mt-4">Búsqueda:</h4>
        <!-- Verifica si la lista de items está vacía -->
        <div v-if="items.length === 0 && !searchProductsLoader" class="w-full text-center">
          <p>No se encontraron productos relacionados con "{{ searchForm.sku }}"</p>
        </div>
        <div v-if="searchProductsLoader" class="w-full text-center">
          <p>
            <v-progress-circular indeterminate color="primary" class="mr-1"></v-progress-circular>
            <span>Buscando productos...</span>
          </p>

        </div>
        <!-- Muestra los result-items si hay resultados -->

        <result-item v-for="item in items" :key="item.MATNR" :item="{
          materialCode: item.MATNR.slice(-6),
          name: item.ARKTX,
          sku: item.MATNR.slice(-6),
        }" @checkboxtoggle="getSKU" />

      </div>
    </v-card>
  </v-dialog>
 <!-- Dialog loading -->
    <!-- Dialog loading -->
    <v-dialog v-model="dialogCartCredit" persistent  width="550" min-width="550" scrollable>
    <v-card>
      <div class="w-full bg-black d-flex justify-content-between align-center px-4">
        <span class="text-white d-flex align-center font-weight-bold">Confirmación de pedido</span>
        <v-btn icon small style="bg-gray" @click="dialogCartCredit = false">
          <v-icon class="text-white font-size-8">mdi-close</v-icon>
        </v-btn>
      </div>
      
      <div class="w-9 bg-white d-flex flex-wrap">
        <h4 class="w-full mt-4 font-weight-bold" style="color: grey;">LÍMITE DE CRÉDITO EXCEDIDO</h4>
        <span class="solid_line"></span>
        <span class="text-title-bk-black font-weight-bold mt-3 mb-5 ">Crédito disponible: ${{ formatPrice(aviable.toFixed(2)) }} </span>
        <span class="text-title-bk-black font-weight-bold mt-3 mb-5 ">Total: <span  v-bind:class="subtotalCred >= aviable ? 'text-title-bk':'text-title-bk-succes'" >${{ formatPrice(subtotalCred.toFixed(2)) }}</span></span>
       </div>
        <v-card-text>
        <div class="w-9 bg-white d-flex flex-wrap">
        <result-item-val-credit class="mr-5 ml-5" v-for="(item, i) in statecart" :key="i.codigo_material" @onClick="updateMatCredit" :item="{            
          materialCode: item.codigo_material.slice(-6),
          name: item.nombre_material,
          sku: item.codigo_material.slice(-6),
          reminder: item.reminder_check, 
          recordatorios: item.recordatorios,
          orderUnits: item.u_pedidas,
          totalCost: item.importe_producto,
          v_credit: item.v_credit,
          orderID:orderId}">
          <div style="width: 60px" class="mr-1">
            <img class="w-full" :src="item.image_url" alt="" />
          </div>
        
        </result-item-val-credit>
         </div>
       </v-card-text>
      <v-card-actions>
        <v-layout justify-center>
        <v-btn class="button-red-cancel text-white font-weight-bold font-size-8" @click="dialogCartCredit = false">
          CANCELAR
        </v-btn>
        <v-spacer></v-spacer>   
        <v-btn
        v-bind:class="subtotalCred >= aviable || subtotalCred <= 0 ? 'button-green-disabled font-weight-bold font-size-8':'button-green text-white font-weight-bold font-size-8'"
        :disabled="subtotalCred >= aviable || subtotalCred <= 0"
        @click="orderGenerate"
        >
        <span class="text-white" style="font-size: 12px;">CONFIRMAR PEDIDO<v-icon>mdi-checkbox-multiple-marked-circle</v-icon></span>
        </v-btn>
      </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Dialog loading -->
  </v-container>

  <!-- PRODUCT IMAGE GALLERY -->
  <CoolLightBox
    v-if="productGalleryImages"
    :items="productGalleryImages"
    :index="productGalleryImageIndex"
    @close="closeProductImageGallery()"
  />

  <!-- PRODUCT PDF FILES MODAL -->
  <product-technical-sheets v-model="isProductTechnicalShetsDialogOpen" :product="product"/>
</div>
</template>


<script>
import Papa from 'papaparse';
import ShoppingCard from "./componets/ShoppingCard.vue";
import SpecialOrderValPop from "./componets/SpecialOrderValPop.vue"; 
import SpecialOrderValPsnw from "./componets/SpecialOrderValPsnw.vue"; 
import SpecialOrderDialogVal from "./componets/SpecialOrderDialogVal.vue"; 
import LoadingBar from "./componets/LoadingBarOrder.vue"; 
import ResultItem from "../quoter_cart/componets/ResultItem.vue"; 
import ResultItemBackorder from "./componets/ResultItemBackorder.vue";

import BlockedByBackordersAlert from '@/components/BlockedByBackordersAlert'
import SpecialDiscountIcon from "./componets/SpecialDiscountIcon.vue";
import DestinationSelector from './componets/DestinationSelector';
import userBlockedByBackorders from '@/mixins/userBlockedByBackorders';
import fullScreenLoader from '@/mixins/fullScreenLoader';

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

import ResultItemValCredit from "./componets/ResultItemValCredit.vue";
import ProductTechnicalSheets from "./componets/ProductTechnicalSheets.vue";

import { mapActions,  mapGetters , mapMutations } from "vuex";
import moment from 'moment'

import axios from "@/plugins/axios";

export default {
name: "ShoppingCart",
computed: {
  ...mapGetters({
    user: "auth/user",
    destinations: "ordercart/destinations",
    statecart: "ordercart/cart",
    statesubtotal: "ordercart/subtotal",
    stateiva: "ordercart/iva",
    statetotal: "ordercart/total",
    increase: "ordercart/increase",
    subtotalCred: "ordercart/subtotalCred",
    lowPrice: "ordercart/lowPrice",
    counItem: "ordercart/counItem",
    orderId: "ordercart/orderId",
    orderName: "ordercart/order",
    currentProduct: 'ordercart/currentProduct',
  }),
  currentPeriod(){
    const today = new Date;
    let firstDay = 1; 
    let year = today.getFullYear();
    let currentMonth = today.toLocaleString('default', { month: 'long' }) 
     currentMonth=  currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
    return ` del (${firstDay} de ${currentMonth} ${year} al ${today.getDate()} de ${currentMonth} del ${year})`;
  },
  isButtonDisabled(){
    if(this.schedule_orders == true){
      return false;
    }else{
    if(this.doctype == 'PSDT'){
      if(this.statecart.length == 0 || this.submitted || this.statetotal < 2500 || this.userIsBlockedByBackorders){
        if(this.statecart.length == 0 ){
          return true;
        }else
        if(this.statecart.some((item) => item.recordatorios == 0)){ 
         if(this.statetotal < 2500 ){
            return true;
          }else{
          return false;
         }
       }
      }
    }

    if(this.doctype == 'PSPO'){
      if(this.statecart.length == 0 || this.submitted || this.customerInfo.PSPO > 0){
        if(this.statecart.length == 0 ){
          return true;
        }else
        if(this.statecart.some((item) => item.recordatorios == 0)){ 
         if(this.statetotal < 0){
            return true;
          }else{
           return false;
         }
       }
      }
    }
    if(this.doctype == 'PSNW'){
      if(this.statecart.length == 0 || this.submitted ||  this.statetotal < 2500 || this.statecart.length < 15 || this.antiguedad < 6 || this.customerInfo.PSNW > 0 || this.customerInfo.PPDP > 36 || this.days_credit > 60 || this.customerInfo.RTRIMA < 20){
        if(this.statecart.length == 0 ){
          return true;
        }else
        if(this.statecart.some((item) => item.recordatorios == 0)){ 
         if(this.statetotal < 2500){
            return true;
          }else{
           return false;
         }
       }
      }
    }
    if(this.doctype == 'PSDR'){
      if(this.statecart.length == 0 || this.submitted ||  this.statetotal < 2500 || this.statecart.length < 10 || this.antiguedad < 6 || this.customerInfo.PSDR > 0 || this.customerInfo.PPDP > 36 || this.days_credit > 60 || this.customerInfo.RTRIMA < 20 || this.customerInfo.RSR6M  < 10){
        if(this.statecart.length == 0 ){
          return true;
        }else
        if(this.statecart.some((item) => item.recordatorios == 0)){ 
         if(this.statetotal < 2500){
            return true;
          }else{
           return false;
         }
       }

      }
    }

    if(this.doctype == 'PSTN'){
      if(this.statecart.length == 0 || this.submitted ||  this.statetotal < 2500 || this.statecart.length < 100 || this.antiguedad < 6 || this.customerInfo.PPDP > 36 || this.days_credit > 60){
        if(this.statecart.length == 0 ){
          return true;
        }else
        if(this.statecart.some((item) => item.recordatorios == 0)){ 
         if(this.statetotal < 2500){
            return true;
          }else{
           return false;
         }
       }
      }
    }
  }
    return false;
  },
  orderSet:{
    get() {
      return this.orderName;
    },
    set(value) {
      this.order = value;
    }
  }
},
components: {
  ShoppingCard,
  LoadingBar,
  SpecialOrderValPop,
  SpecialOrderValPsnw,
  SpecialOrderDialogVal,
  ResultItem,
  ResultItemBackorder,
  BlockedByBackordersAlert,
  ResultItemValCredit,
  SpecialDiscountIcon,
  DestinationSelector,
  CoolLightBox,
  ProductTechnicalSheets,
},

mixins: [userBlockedByBackorders, fullScreenLoader],

data: () => ({
  submitted: false,
  hasError: null,
  dialogLoading: false,
  destiny: "",
  cart: [],
  dialogBackOrder: false,
  order_response: "",
  invoice_response:"",
  delivery_response: "",
  purchase_response:"",
  doctype: null,
 customerInfo: {
    NAME1: null,
    KUNNR: null,
  },
  searchForm: {
    valid: false,
    sku: null,
    quantity: 1,
  },
  quantityDialog: {
    show: false,
    id: null,
    name: null,
    quantity: null,
    item: null,
  },
  actualDate: null,
  porcentaje: null,
  aviable: null,
  antiguedad:null,
  days_credit: 0,
  dialogSpecilaOrder: false,
  param_selected:null,
  purchase_validation: 1,
  searchProductDialog: false,
  items:[],
  showNameField: false,
  loading: false,
  docven: null,
  loadingCustomerinformation:true,
  searchProductsLoader:false,
  idq:null,
  customer_code:null,
  sellerOrderAviable: true,
  type: null,
  numero_vendedor:null,
  pathHome:null,
  customerInfo_1:[],
  today2: moment().format('YYYY/MM/DD'), 
  itemCSV:[],
  schedule_orders:false,
  menu1: false,
  schedule_date:  moment().add(1, 'days').format('YYYY/MM/DD'),
  minDate:  moment().add(1, 'days').format('YYYY/MM/DD'),
  totalCred:0,
  dialogCartCredit: false,
  message_response: null,
  orderSecondResponse: null,
  blockItemCart: false,
  seendingOrder:false,
  order: null,
  productGalleryImages: [],
  productGalleryImageIndex: null,
  isProductTechnicalShetsDialogOpen: false,
  product: null,
}),
async mounted() {

  this.generarPDF();

  if (this.$hasAnyRole(['Customer','CustomerPR'])) {
  this.customer_code =  this.user.custumer_code;
  this.type = 'CL';
  this.numero_vendedor = this.user.custumer_code;
  this.pathHome = '/';
 }else{
  const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
        if (currentCustomerInfo.length<1) {
        this.$router.push({name:'Home'})
        } 
  this.customerInfo_1 = currentCustomerInfo;
  this.customer_code =  parseInt(currentCustomerInfo?.KUNNR,10) 
  this.sellerOrderAviable = false;
  this.type = 'VE';
  this.numero_vendedor = this.user.employee_number;
  this.pathHome = 'CustomerCoverDetails';
 } 
  
  this.doctype = this.$route.params.doctype;
  this.idq = this.$route.params.idq;
  console.log("doctype");
  console.log(this.doctype);
  this.getInfoCustomer();
  this.actualDate = this.getCurrentDateTime();

  try {

    await this.loadCustomer(); // Loads previous selcted customer
    this.validateUserBlockedByBackorders();

    this.loadCustomerDestinations({
      // custumer_code: this.user.custumer_code,
      custumer_code: this.customer_code,
      VKORG: this.user.VKORG,
      VTWEG: this.user.VTWEG,
      VKBUR: this.user.VKBUR,
    });

    await this.loadOrder({
      //custumer_code: this.user.custumer_code,
      custumer_code: this.customer_code,
      user_id: this.user.id,
      type: this.type,
      VTWEG: this.user.VTWEG,
      doctype: this.doctype,
      idq: this.idq,
    });

    await this.loadCartItems({
      // custumer_code: this.user.custumer_code,
      custumer_code: this.customer_code,
      VKORG: this.user.VKORG,
      VTWEG: this.user.VTWEG,
      VKBUR: this.user.VKBUR,
      doctype: this.doctype,
    });

    this.desactiveLoader();
    
    //validacion compra minima de 2,500
    if (this.statetotal < 2500 && this.doctype != "PSPO") {
      console.log("Total:" + this.statetotal);
      this.$toast.error("El monto mínimo de compra es $ 2,500.00 MXP");
    }

    if (this.statetotal > this.aviable) {
      console.log("aviable:" + this.aviable);
      //this.purchase_validation = 2
      this.$toast.info("Ha superado el Limite de crédito, El pedido será bloqueado por crédito");
    }

    if (this.customerInfo.DOCVENC > 0) {
      console.log("DOCVENC:" + this.customerInfo.DOCVENC);
      //this.purchase_validation = 2

      this.$toast.info("El cliente tiene documentos vencidos, El pedido será bloqueado por crédito");
    }


    if (this.doctype == "PSNW") {
      if (this.statecart.length < 15) {
        this.$toast.error("Para generar el pedido deben ser minimo 15 renglones (Partidas) dentro del carrito");
      }
    }
  } catch (error) {
    this.desactiveLoader();
  }
},

methods: {
  ...mapMutations({
    updateState: "ordercart/UPDATE_STATE",
    setCurrentProductIndex: 'ordercart/SET_CURRENT_PRODUCT_INDEX',
  }),
  ...mapActions({
    loadCustomerDestinations: "ordercart/loadCustomerDestinations",
    loadOrder: "ordercart/loadOrder",
    loadCartItems: "ordercart/loadCartItems",
    getMaterialInfo: "ordercart/getMaterialInfo",
    saveNewMaterialOrder: "ordercart/saveNewMaterialOrder",
    deleteMaterialOrder: "ordercart/deleteMaterialOrder",
    updateMaterialQuantity: "ordercart/updateMaterialQuantity",
    generateOrder: "ordercart/generateOrder",
    turnOrderIntoQuoter: "quotecart/turnOrderIntoQuoter",
    changeOrderType: "ordercart/changeOrderType",
    cleanMaterialOrder: "ordercart/cleanMaterialOrder",
    updateMaterialCredit: "ordercart/updateMaterialCredit",
    sendToExcel: 'printer/sendToExcel',
    sendToPDF: 'printer/sendToPDF',
    loadCustomer: 'selectedUser/loadCustomer',
    updateOrderName: "ordercart/updateOrderName",
    getProductImages: 'ordercart/getProductImages',
  }),
  generarPDF(){

          this.sendToPDF({active: true});
  },
  generarExcel(){
    let newItems = []
    this.itemCSV = []

    for (let key in this.statecart) {                
              let item = this.statecart[key];
              newItems.push({
                      'Producto': item.codigo_material ,
                      'Nombre': item.nombre_material,
                      'Genetica': item.material_type,
                      'Cantidad': item.u_confirm,
                      'Empaque': item.empaque,
                      'Unidad de Medida.':item.unidad_medida,
                      'Pedido Mensual.': item.PMCOM,
                      'Dias solicitados.': item.DSINV,
                      'Precio de lista': item.precio_lista,
                      'Precio C/ descuento':item.precio_con_descuento,
                      'Importe Total':item.importe_producto,
                  });
            }
    this.itemCSV = newItems

    let objectCSV = {
              'name': `Pedidos-${this.today2}`,
              'items': this.itemCSV,
              'showLabels': true
          }

  this.sendToExcel(objectCSV)
  },
  saveOrderName(){
    try {
        const response = this.updateOrderName({
          orderId: this.orderID,
          orderName: this.order,
        })
        return response.data;
     } catch (error) {
        this.error = "No se pudo obtener la información del cliente"
     }
  },
  updateMatCredit(event,item){
    //alert(event);
    //console.log(item);
    try {
        const response = this.updateMaterialCredit({
          orderId: item.orderID,
          sku: item.materialCode,
          credit: event,
          custumer: this.customer_code,
          quantity: item.orderUnits,
          doctype: this.$route.params.doctype,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        
        return response.data;
     } catch (error) {
        this.error = "No se pudo obtener la información del cliente"
     }
  },
  onEnterCode() {
        this.$refs["units"].focus();
  },
  getInfoCustomer() {
    this.loadingCustomerinformation =true;
    axios
      .post("infoCustomer", {
       // customer: this.user.custumer_code,
        customer: this.customer_code,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
      })
      .then((response) => {
        this.customerInfo = response.data;
        this.saldo = this.customerInfo.SKFOR.replace(/,/g, "");
        this.limite = this.customerInfo.KLIMK.replace(/,/g, "");
        this.porcentaje =
          (parseInt(this.saldo) * 100) / parseInt(this.limite);
        this.porcentaje = parseInt(this.porcentaje);
        this.aviable = this.customerInfo.CREDD.replace(/,/g, "");
        this.aviable = (this.aviable*1);

        this.totalCred = parseInt(this.aviable) - this.statecart;

        this.days_credit = parseInt(this.customerInfo.VTEXT.match(/\d+/g));

        this.validateOrderPurchase(this.customerInfo,this.doctype);

        const taxkd = this.customerInfo.TAXKD;
      this.$store.commit('ordercart/SET_CUSTOMER_IVA', taxkd);
  
      })
      .catch((error) => {
        this.loadingCustomerinformation =false;
        console.log(error);
      })
      .finally(() => {
        this.loadingCustomerinformation =false;
      });
  },
  getSKU(eventsku) {
    // Obtener SKU
    const sku = eventsku.item.materialCode.slice(-6);

    // Obtener Name
    const name = eventsku.item.name; // Reemplaza 'name' con el nombre real del campo

    // Asignar valores a searchForm
    this.searchForm.sku = sku;
    this.searchForm.name = name;
    this.searchForm.url = eventsku.url
    this.showNameField = true;

    this.searchProductDialog = false;
  },
  clearSku() {
    this.searchForm.sku = "";
    this.showNameField = false;
  },
  async addItemToCart(){

    if (this.searchForm.sku == "" || this.searchForm.sku == null) {
      this.$toast.warning("El Código de material es requerido");
      return false;
    } 
    var ExpRegSoloNumeros="^[0-9]+$";
    if (! /^[0-9]+$/.test(this.searchForm.quantity)) {
      this.$toast.warning("La unidad debe ser un número válido");
      return false;

    }
    if (this.searchForm.quantity <= 0) {
      this.searchForm.quantity =1
    }
    let index = this.statecart.findIndex(
      (item) => item.codigo_material == this.searchForm.sku
    );
    if (index >= 0) {
      this.$toast.warning("El código de material ya está en el carrito");
      return false;
    }
    
      //Evaluación de Cadena Invalida de Solo Números     
   if( this.searchForm.sku.match(ExpRegSoloNumeros)==null){
      this.searchProductsLoader = true;
      this.searchProductDialog = true;
      axios.post("/materialComplete",{name:this.searchForm.sku}).then(response=>{
    
        this.items=response.data;
      })
      .catch(()=> {
      this.searchProductsLoader = false;
      })
      .finally(()  => {
      this.searchProductsLoader = false;
      })
   } else{
    try {
      this.loading = false;
      this.blockItemCart = true;
      const product = await this.getMaterialInfo({
        sku: this.searchForm.sku,
        quantity: this.searchForm.quantity,
        //custumer_code: this.user.custumer_code,
        custumer_code: this.customer_code,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
        order_type: this.doctype,
      });
      await this.saveNewMaterialOrder({
        order_id: this.orderId,
        item: product,
      });

      if(this.searchForm.quantity < product.empaque || product.u_confirm == 0){
          await this.updateMaterialQuantity({
          codigo_material: product.codigo_material,
          quantity: product.u_confirm == 0 ? product.recordatorios:product.u_confirm,
          precio_con_descuento:"",
          custumer_code: this.customer_code,
          order_type: this.doctype,
         });

         
         this.$refs.searchMaterialForm.reset();
         this.$refs.searchMaterialForm.resetValidation();  
         this.searchForm.sku = '';
         this.searchForm.quantity = 1;
         this.showNameField = false;
         this.blockItemCart = false;
         this.loading = false;
         this.$refs.input.focus();
         this.$toast.success("Producto agregado");
         this.totalCred = parseInt(this.aviable) - this.statecart;
         
      }else{
      this.searchForm.sku = '';
      this.searchForm.quantity = 1;
      this.showNameField = false;
      this.loading = false;
      this.$refs.input.focus();
      this.blockItemCart = false;
      this.$toast.success("Producto agregado");
      this.totalCred = parseInt(this.aviable) - this.statecart;
      }
    } catch (error) {
      if (error.response?.data?.error_code) {
        this.$toast.warning(error.response?.data?.error_code);
        this.blockItemCart = false;
      } else {
        this.blockItemCart = false;
      }
    }
  }
  },
  resetIncrease() {
    this.updateIncreaseState(0);
  },
  editQuantity(item, cant) {
    let quantity = parseInt(cant);
    /*this.quantityDialog.quantity = quantity;
    this.quantityDialog.name = item.nombre_material;
    this.quantityDialog.id = item.id;
    this.quantityDialog.item = item;
    this.quantityDialog.show = true;*/
    this.saveQuantity(item,quantity);
  },
  cancelQuantity() {
    this.quantityDialog.quantity = null;
    this.quantityDialog.name = null;
    this.quantityDialog.id = null;
    this.quantityDialog.item = null;
    this.quantityDialog.show = false;
  },
  saveQuantity(item,quantity) {
    this.updateMaterialQuantity({
      custumer_code: this.customer_code,
      //codigo_material: this.quantityDialog.item.codigo_material,
      //quantity: this.quantityDialog.quantity,
      //precio_con_descuento: this.quantityDialog.item.precio_con_descuento,
      codigo_material: item.codigo_material,
      quantity: quantity,
      precio_con_descuento:item.precio_con_descuento,
      cart_i: this.statecart,
      order_type: this.doctype,
    })
      .then((data) => {
        console.log(data);
        this.$toast.success("Producto actualizado");
        this.totalCred = parseInt(this.aviable) - this.statecart;
        this.cancelQuantity();
      })
      .catch((error) => {
        console.log(error);
        this.$toast.error(error);
      });
  },
  cleanOrder(){
    this.cleanMaterialOrder({
      order_id: this.orderId,
    })  .then((data) => {
        console.log(data);
        this.$toast.success("Se limpio el Pedido.");
        this.totalCred = parseInt(this.aviable) - this.statecart;
       // window.location.reload();
        
      })
      .catch((error) => {
        console.log(error);
        this.$toast.error(error);
      });
  },
  generateFiles() {
    //this.downloadPdf();
    this.exportToCSV();
  },
  formatInt(value) {
    try {
      return parseInt(value);
    } catch (error) {
      return value;
    }
  },
  formatPrice(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getItemTextOrderType(item) {
    return `(${item.type}) ${item.name}`;
  },
  format(value) {
    let result = Number(value).toFixed(2);
    return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  checkBackOrder() {

    this.hasError = null;
    if(this.isButtonDisabled == true){
    if(this.statetotal < 2500 && this.doctype != "PSPO"){
       this.$toast.error("El monto mínimo de compra es $ 2,500.00 MXP");

    }else if (this.doctype == "PSPO"){
      if (this.customerInfo.PSPO > 0) {
        this.$toast.info("El cliente ya tiene generado un Pedido POP este Trimestre, puede realizar un nuevo pedido hasta el siguiente trimestre.",
          {
            hideProgressBar: false,
          });
      }
    }else if(this.doctype == "PSNW"){
        if (this.antiguedad < 6) {
          this.$toast.info("Para generar el pedido el cliente debe de tener 1 año de antiguedad",
            {
              hideProgressBar: false,
            });
        }

        if(this.customerInfo.PPDP > 36){
          this.$toast.info("Para generar el pedido el Cliente debe de tener un Promedio Ponderado de Días de Pago de 36 días",
            {
              hideProgressBar: false,
            });
        }

        if(this.customerInfo.PSNW > 0){
          this.$toast.info("El cliente ya tiene generado un Pedido de Nuevos Productos este Mes",
            {
              hideProgressBar: false,
            });
        }

        if(this.customerInfo.RTRIMA < 20){
          this.$toast.info("El cliente debe de haber facturado al menos 20 renglones en el ultimo trimestre, para poder realizar este tipo de pedido",
            {
              hideProgressBar: false,
            });
        
        if(this.statecart.length < 15){
          this.$toast.error("Para generar el pedido deben ser minimo 15 renglones (Partidas) dentro del carrito");
        }
        }
      }
      }else if (this.isButtonDisabled == false){
        const existBackOrder = this.statecart.some((item) => item.recordatorios > 0);
        const limitCredit = this.statetotal >= this.aviable ? true : false;
        if (existBackOrder) {
            this.dialogBackOrder = true;
        } else {
         if(limitCredit){
          if(this.customerInfo.DOCVENC  > 0 || this.customerInfo.AUFSD == 1 || this.aviable <= 1 || this.lowPrice > this.aviable){
            this.orderGenerate();
          }else{
            this.dialogCartCredit = true;
          }
         }else{
          this.orderGenerate();
         }
      }
      }
  },
  orderGenerateCredit(){
    const limitCredit = this.statetotal >= this.aviable ? true : false;
    const existBackOrder = this.statecart.some((item) => item.recordatorios > 0);
    if(limitCredit){
      if(this.customerInfo.DOCVENC  > 0  || this.customerInfo.AUFSD == 1 || this.aviable <= 1 || this.lowPrice > this.aviable){
        this.orderGenerate();
      }else{
        if(existBackOrder){
          this.orderGenerate();
        }else{
          this.dialogCartCredit = true;
        }
      }
    }else{
      this.orderGenerate();
    }
  },
  orderGenerate() {

   this.seendingOrder = true;
   this.dialogBackOrder = false;
   this.dialogLoading = true;
   this.submitted = true;

   if(this.order == null){
    this.order = this.orderName;
  }

   if(this.customerInfo.DOCVENC  > 0 || this.customerInfo.AUFSD == 1 || this.aviable <= 1 || this.lowPrice > this.aviable){
      this.purchase_validation = 2;
   }else{
      this.purchase_validation = 1;
   }

    this.generateOrder({
      custumer_code: this.customer_code,
      orden_compra: this.order,
      carrito: this.statecart,
      orderType: this.doctype,
      destiny: parseInt(this.destiny.KUNNR),
      banderaProgramado: "",
      purchase_validation: this.purchase_validation,
      numero_vendedor: this.numero_vendedor,
      schedule_orders: this.schedule_orders,
      schedule_date: this.schedule_date,
      quoter: this.$route.params.idq,
    })
      .then((response) => {
       console.log(response);
       // console.log(response.data.RDCreaEntrega);
       if(response.message == "BACKORDER GENERADO" ){
       
        this.$swal.fire(response.message, 'Número de Pedido: S/N','success');
        this.$router.replace({
          name: "HistoryOrders",
        });
      
      }else{
        this.dialogLoading = false;
        this.order_response = response.VBELN;
        this.orderSecondResponse = response.VBELN2 == '' ? '': `Pedido enviado a Crédito ${response.VBELN2}`;
        this.invoice_response = response.FACTURAS;
        this.delivery_response = response.ENTREGAS;
        this.purchase_response = response.PVALID;
        this.message_response = response.MYERR == 0 ? '':response.FNMSG;
        this.cart = [];
        this.order = null;

        const message = `Pedido: ${this.order_response}.
                         Entrega: ${this.delivery_response}.
                         Factura: ${this.invoice_response}.
                         ${this.orderSecondResponse}.
                         ${this.message_response}.`;

        if(this.purchase_response == 1){
          this.$swal.fire('Pedido Generado', message, 'success');
        }else{
          this.$swal.fire('Pedido Generado', message + '*Bloqueado por Credito.', 'success');
        }       
         this.$router.replace({
          name: "HistoryOrders",
        });
      }
      })
       .catch((error) => {
        console.log(error);
        this.seendingOrder = false
       this.dialogLoading = false;
       if(error.response.status == 404){
          //alert(error.response.data.error_code);
            if(error.response.data.message == "BACKORDER GENERADO") {
              this.$swal.fire(
                error.response.data.message,
                "Número de Pedido: S/N",
                "success"
              );
              this.$router.replace({
                name: "HistoryOrders",
              });
            }else{
              this.$swal({
              title: error.response.data.error_code,
              icon: "error",
              type: "error",
            }).then((result) => {
              console.log(result);
             // location.reload();

            });
            }
          }else{
            //alert(error);
            console.log(error);
          }

        this.submitted= false;
      })
      .finally(() => (
        this.dialogLoading = false, 
        this.submitted= false,
        this.seendingOrder = false
      ));
  },
  goHistoryOrdes(){
    this.$router.replace({
          name: "HistoryOrders",
        });
  },
  goOrderCSV(){
    this.$router.replace({
          name: "upload-file",
    });
  },
  deleteItem(item) {
    this.deleteMaterialOrder({ order_id: this.orderId, item }).then(() => {
      this.$toast.success("Producto elminado del carrito");
      this.totalCred = parseInt(this.aviable) - this.statecart;
    });
  },
  goSpecialOrder(param){
    console.log(param);

    if (param == 'PSDT'){
      axios
      .post("changeOrderType", {
        order_id: this.orderId,
        order_type: param,
      })
      .then((response) => {
          console.log(response);
         this.$router.push({ name: 'NewShoppingCart', params: { doctype: param } }).then(() => {
              this.$router.go(0)
              window.location.reload()
          }).catch((error) => {
              if (error.name != "NavigationDuplicated") {
                  throw error;
              }
          });
      }).catch((error) => {
        //alert(error);
        console.log(error);
        this.errormsg = true;
      }).finally();
    }else{
      this.dialogSpecilaOrder = true;
      this.param_selected = param;
    }


  },
  formatDestinyText(item) {
    return `${item.KUNNR} ${item.STRAS} ${item.ORT01} ${item.PSTLZ}`;
  },


 /* showSearchProductDialog() {
    this.searchProductDialog = true;
  },*/
  closeDateMenu1() {
      this.menu1 = false;
      this.schedule_date= new Date(
        this.schedule_date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
        console.log(this.schedule_date);
    },
  createQuoter() {
    this.turnOrderIntoQuoter({
      custumer_code: this.customer_code,
      order_id: this.orderId,
      user_id: this.user.id,
      cart: this.statecart,
      type: this.type,
      doctype: this.doctype,
      channel: this.user.VTWEG,
      total: this.statetotal,
    })
      .then((data) => {
        console.log(data);
        this.$router.push({ name: 'QuoterHistory'});

      })
      .catch((error) => {
        console.log(error);
        this.$toast.error(error);
      });
  },
  exportToCSV() {
 // const headers = ["Version", "Codigo de productos", "Orden de Compra"];
 // const dataLine2 = ["1.3.0", "IUSA", "OC-0"];
  const data = this.statecart.map((item) => {
    return [
    `${this.formatInt(item.u_confirm)}`,
      //`${this.formatInt(item.u_confirm)} ${item.recordatorios !== 0 ? ` BACKORDER ${item.recordatorios}` : ""}`,
      item.codigo_material,
      item.nombre_material,
    ];
  });

  const csvData = [["Version", "Codigo de productos", "Orden de Compra"],["1.3.0", "IUSA", "OC-0"],['Cantidad','Código', 'Nombre' ]];
  csvData.push(...data);

  // Convertir a CSV
  const csv = Papa.unparse(csvData);

  // Descargar el archivo CSV
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Pedidos.csv';
  a.click();
 },
  validateOrderPurchase(customerInfo,doctype){

    console.log(doctype);

      if(customerInfo.AUFSD == 1){
       this.$toast.info("Estimado cliente usted tiene un bloqueo de partidas vencidas, Será bloqueado por crédito",
        {
          hideProgressBar: true,
          timeout: false,
        });
      }

      if(doctype == "PSNW"){
        if (this.antiguedad < 6) {
          this.$toast.info("Para generar el pedido el cliente debe de tener 1 año de antiguedad",
            {
              hideProgressBar: false,
            });
        }

        if(this.customerInfo.PPDP > 36){
          this.$toast.info("Para generar el pedido el Cliente debe de tener un Promedio Ponderado de Días de Pago de 36 días",
            {
              hideProgressBar: false,
            });
        }

        if(this.customerInfo.PSNW > 0){
          this.$toast.info("El cliente ya tiene generado un Pedido de Nuevos Productos este Mes",
            {
              hideProgressBar: false,
            });
        }

        if(customerInfo.RTRIMA < 20){
          this.$toast.info("El cliente debe de haber facturado al menos 20 renglones en el ultimo trimestre, para poder realizar este tipo de pedido",
            {
              hideProgressBar: false,
            });
        }
      }

    if (doctype == "PSPO"){
      if (customerInfo.PSPO > 0) {
        this.$toast.info("El cliente ya tiene generado un Pedido POP este Trimestre, puede realizar un nuevo pedido hasta el siguiente trimestre.",
          {
            hideProgressBar: false,
          });
      }
    }
  },
  getCurrentDateTime() {
    const now = new Date();
  
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
  
    let hours = now.getHours();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
  
    const minutes = String(now.getMinutes()).padStart(2, '0');
  
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  
    return formattedDateTime;
  },
  seePorcent(value) {
  return parseFloat(value * 100).toFixed(2);
},
  async openProductImageGallery(product, index) {

    this.setCurrentProductIndex(index);

    if(!product.images) {

      this.productGalleryImages = [{
        title: 'Cargando...', 
        description: 'Cargando...', 
        src: product.image_url
      }];

      this.productGalleryImageIndex = 0;
      this.getProductImages(index).then((images) => {
        this.productGalleryImages = images;
      });
      
    } else {
      this.productGalleryImages = this.currentProduct.images;
      this.productGalleryImageIndex = 0;
    }
  },
  closeProductImageGallery() {
    this.productGalleryImageIndex = null;
    this.productGalleryImages = [];
  }
},
watch: {
  statecart: {
          immediate: true,
          handler() {
              this.generarExcel()
          },
      },   
  totalCred:{
      immediate: true,
      handler(){
        var aviable = this.customerInfo.CREDD.replace(/,/g, "");
        var aviableFormat = (aviable*1);
       //this.totalCred = parseFloat(aviableFormat) - parseFloat(this.statetotal);
       this.totalCred= parseFloat(aviableFormat)  - parseFloat(this.statetotal);
      }
   } 
  }, 
};
</script>
<style scoped src="../../../public/css/shopping.css"></style>


